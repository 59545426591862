import Vue from 'vue';
import Vuex from 'vuex';
// import _ from 'lodash';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    // Variables del store
    pocillosBitacora: [],
    enableEdit: true,
    parametrosCliente: [],
    userInfo: [],
  },
  mutations: {
    setvariableSampleList(state, data) {
      state.pocillosBitacora = data;
    },
    setenableEditresult(state, data) {
      state.enableEdit = data;
    },
    getParametrosCliente(state, data) {
      state.parametrosCliente = data;
    },

    getUserInfo(state, data) {
      state.userInfo = data;
    },
  },
  actions: {

  },
};
