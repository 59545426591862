const nl = {
  'Tradicional Microbiology': 'Traditionele Microbiologie',
  'Molecular Testing': 'Moleculaire Testen',
  'Chemical Testing': 'Chemische Testen',
  Other: 'Andere',

  'Raw material': 'Grondstof',
  'Finished product': 'Eindproduct',
  'Semi elaborated': 'Half uitgewerkt',
  'Fish and fishery products': 'Vis en visserijproducten',
  'Materia prima': 'Grondstof',
  'Producto terminado': 'Eindproduct',
  'Semi elaborado': 'Half uitgewerkt',
  'Pescado y productos pesqueros': 'Vis en visserijproducten',

  'zone 1': 'Zone 1',
  'zone 2': 'Zone 2',
  'zone 3': 'Zone 3',
  'zona 1': 'Zone 1',
  'zona 2': 'Zone 2',
  'zona 3': 'Zone 3',

  Critical: 'Kritiek',
  'Not critical': 'Niet-kritiek',
  Crítica: 'Kritiek',
  'No crítica': 'Niet-kritiek',

  'Pre-sanitization': 'Voorontsmetting',
  'Post-sanitization': 'Na-ontsmetting',
  'In use': 'In gebruik',
  'With gloves': 'Met handschoenen',
  'Pre-sanitización': 'Voorontsmetting',
  'Post-sanitización': 'Na-ontsmetting',
  'En uso': 'In gebruik',
  'Con guantes': 'Met handschoenen',

  'Agua de Tratamiento': 'Behandelingswater',

  meses: {
    enero: 'Januari',
    febrero: 'Februari',
    marzo: 'Maart',
    abril: 'April',
    mayo: 'Mei',
    junio: 'Juni',
    julio: 'Juli',
    agosto: 'Augustus',
    septiembre: 'September',
    octubre: 'Oktober',
    noviembre: 'Oktober',
    diciembre: 'December',
  },
  submenu: {
    Workordersandrequests: 'Werkorders en -aanvragen',
    createneworder: 'Nieuwe order aanmaken',
    requestslist: 'Lijst met aanvragen',
    preworkorderlist: 'Prewerkorderlijst',
    workorderlist: 'Werkorderlijst',
    completeworkorders: 'Afgewerkte werkorders',
    samples: 'Monsters',
    plantsandlocations: 'Bedrijf en testlocaties',
    laboratorytest: 'Laboratoriumtesten',
    staffmanager: 'Personeelsmanager',
    resultreport: 'Resultaatrapport',
    accountinformation: 'Accountgegevens',
    subscriptions: 'Abonnementen',
    laboratories: 'Laboratoria',
    worksheet: 'Binnacle',
  },
  menu: {
    worksheet: 'Binnacle',
    cargando: 'Bezig met laden',
    WorkOrdersyRequests: 'Werkorders en -aanvragen',
    RequestsList: 'Lijst met aanvragen',
    Dismiss: 'Sluiten',
    crear_ot: 'Nieuwe order aanmaken',
    pre_ot: 'Prewerkorderlijst',
    ot_muestra: 'Werkorderlijst',
    ot_com: 'Werkorders voltooien',
    lote_medio: 'Partij reagentia',
    analisis: 'Analyse van de PCR-resultaten',
    ensayo: 'Labtesten',
    setting: 'Instellingen',
    language: 'Taal',
    salir: 'Uitloggen',
    aprende: 'Trainingscentrum',
    cerrar: 'Sluiten',
    samples: 'Monsters',
    Plants_locations: 'Installaties en locaties',
    Laboratory_test: 'Laboratoriumtest',
    Users: 'Gebruikersbeheer',
    Analysts_supervisors: 'Analisten en supervisors',
    Result_report: 'Resultaatrapport',
    Account_information: 'Accountgegevens',
    staff: 'Personeelsmanager',
    youplan: 'Uw plan',
    labs: 'Laboratoria',
    soli: 'Aanvragen',
    soli_ingresada: 'Ingevoerde aanvragen',
    soli_coo: 'Coördinatie van aanvragen',
  },
  analisis: {
    btn_confirm: 'bevestigen',
    enter_pass: 'Voer uw wachtwoord in om door te gaan',
    no_equipo_compatible: 'Deze blog is niet compatibel met de geselecteerde apparatuur',
    medio: 'Voorbeeld',
    no_medio_and_equipo: 'Het is noodzakelijk om een ​​team en een essay te selecteren om door te gaan',
    medioSelect: 'Selecteer medium',
    no_datos_bitacora: 'Er is geen logboek met deze gegeven',
    abandonar: 'Wilt u de site verlaten?',
    interno: 'Intern',
    Reviewyouranalysisresultsfor: 'Uw analyseresultaten controleren voor',
    medioanalisis: 'Analyse',
    selectanalisis: 'Analyses selecteren',
    clase: 'Klasse',
    Clase: 'Klasse',
    well: 'Putje',
    Other: 'Andere',
    DETERIORADO: 'BEDERF',
    spoilage: 'BEDERF',
    NODETERIORADO: 'NIET-BEDERF',
    nonspoilage: 'NIET-BEDERF',
    deteriorado: 'BEDERF',
    nodeteriorado: 'NIET-BEDERF',
    positivo: 'Positief',
    negativo: 'Negatief',
    inhibido: 'Belemmerd',
    vacio: 'Leeg',
    zonagris: 'Grijze zone',
    microorganismonoregistrado: 'Niet-geregistreerd micro-organisme',
    alimentosdeusoinfantil: 'Babyvoeding',
    carnesyproductoscarneos: 'Vlees en vleesproducten',
    pescadosyproductosdelapesca: 'Vis en visserijproducten',
    huevosyovoproductos: 'Eieren en eiproducten',
    salsasaderezosespeciasycondimentos: 'Sauzen, dressings, kruiden en specerijen',
    comidasyplatospreparados: 'Bereide maaltijden en gerechten',
    productosdepanaderiaypasteleria: 'Bakkerij- en banketbakkerijproducten',
    frutasyverduras: 'Groenten en fruit',
    bebidasyaguas: 'Dranken en water',
    estimulantesyfruitivos: 'Stimulerende en fruitige middelen',
    conservas: 'Conserven',
    noaplica: 'Niet van toepassing',
    lechesyproductoslacteos: 'Melk en melkproducten',
    heladosymezclasparahelados: 'IJs en ijsmengsels',
    productosgrasos: 'Vetrijke producten',
    caldossopascremasymezclasdeshidratadas:
      'Bouillons, soepen, roomsoepen en gedehydreerde mengsels',
    productoselaboradosapartirdecereales: 'Producten op basis van granen',
    azucaresymiel: 'Honing en suikers',
    productosdeconfiteria: 'Zoetwaren',
    agua: 'Water',
    alimentos: 'Voedingsmiddelen',
    ambientes: 'Omgevingen',
    manipuladores: 'Hanteerders',
    superficies: 'Oppervlakken',
    controlpositivo: 'Positieve controle',
    controlnegativo: 'Negatieve controle',
    water: 'Water',
    foods: 'Voedingsmiddelen',
    environments: 'Omgevingen',
    handlers: 'Hanteerders',
    surfaces: 'Oppervlakken',
    positivecontrol: 'Positieve controle',
    negativecontrol: 'Negatieve controle',
    cePositivo: 'Positieve extractiecontrole',
    ceNegativo: 'Negatieve extractiecontrole',
    cMedio: 'Middelmatige controle Positieve',
    cnMedio: 'Middelmatige controle Negatieve',
    noResults: 'Er zijn geen resultaten voor deze parameters',
    repetirReaccion: 'Profielfout, herhaal reactie',
    reaccionGris: 'Grijze zone, herhaal reactie',
    toolp: 'Selecteer zoveel putjes als u wilt, en wijs er vervolgens automatisch bepaalde parameters aan toe, zoals matrix, voedingscategorie en bemonsteringsfrequentie.',
    analizar: 'Analyseren',
    txt_1: 'Uw PCR-resultaten analyseren',
    txt_2:
      'Uw onbewerkte PCR-bestand uploaden voor een automatische interpretatie van de resultaten',
    txt_3: 'Uw labtest instellen',
    txt_4: 'Uw monsters instellen',
    txt_5: 'Monsters manueel invoeren',
    txt_6: 'Een werkorder analyseren',
    matriz: 'Matrix',
    sub_matriz: 'Deelmatrix',
    sube: 'Upload uw PCR-bestand en wij analyseren uw resultaten!',
    error: 'Fout bij het lezen van de structuur van het geselecteerde bestand',
    mn: 'Monster niet gevonden',
    m: 'Monster gevonden',
    formato_invalido: 'Ongeldige bestandsindeling. Voer een bestand in met de extensie',
    no_datos: 'Geen gegevens gevonden met OT',
    no_muestra: 'Er zijn geen monsters met een Steriplex-kit in de WO',

    bienvenido: 'Welkom',
    analiza: 'Uw resultaten analyseren van',
    selecciona_equipo: 'Uw PCR-test selecteren',
    carga: 'Uw PCR-bestand uploaden',
    Selecciona: 'Uw PCR-apparatuur selecteren',
    ingresa: 'Werkordernummer typen',
    categoria: 'Categorie',
    revisar_ot: 'Controleren',
    analizar_resultados: 'Resultaten analyseren',
    forma_manual: 'Uw monsters manueel invoeren',
    acciones: 'Acties',
    aplicar_matriz: 'Matrix toepassen',
    estado: 'Status',
    codigo_muestra: 'Monstercode',
    estado_muestra: 'Monsterstatus',
    ingresa_codigo: 'Code invoeren',
    seleccionar_matriz: 'Matrix selecteren',
    seleccionar_kit: 'Categorie selecteren',
    seleccionar_status: 'Status selecteren',

    Resultados_analisis: 'Resultaten van uw analyse',

    muestras_publicadas: 'De monsters werden',
    muestras_publicadas_txt1: 'met succes gepubliceerd,',
    muestras_publicadas_txt2: 'u kunt ze controleren in ',

    sin_ingresar_muestra: 'Geen monsters van',
    sin_ingresar_muestra_txt1: 'te publiceren',

    el_param: 'Parameter',
    el_param_txt1: 'van het monster',
    el_param_txt2: 'het werd niet gepubliceerd',

    la_muestra: 'Het monster',
    la_muestra_txt1: 'werd niet gepubliceerd omdat',
    la_muestra_txt2: 'Voer de PCR opnieuw uit.',

    pocillo: 'Putje',
    muestra: 'Monster-ID',
    descripcion: 'Monsterbeschrijving ',
    sample_status: 'Monsterstatus',
    origen: 'Oorsprong',
    resultado: 'Resultaat',

    otro: 'Andere',

    generar_pdf: 'Pdf genereren',
    publicar: 'Publiceren',
    salir: 'Afsluiten',

    m_resultados: 'Monster met resultaten',
    m_resultados_txt1: 'Het monster heeft al resultaten en is al gepubliceerd.',
    m_resultado_txt2: 'Wilt u opnieuw posten?',

    aceptar: 'Accepteren',
    cancelar: 'Annuleren',

    // modal analisis

    _ot: 'Werkorder',
    la_ot_txt1: 'bevat',
    la_ot_muestra_txt2: 'monsters',
    la_ot_muestra_txt3: 'monster',
    seleccionar_kit_pcr: 'PCR-kit selecteren',
    kit_pcr: 'PCR-kit',
    Selectlaboratorytest: 'Laboratoriumtest selecteren',
    c_negativo: 'Negatieve controle',
    c_positivo: 'Positieve controle',
    limpiar: 'Wissen',
    txt1: 'Vergeet niet te controleren of elk putje dat aan uw monster is toegewezen, gekoppeld is aan de bijbehorende labtest, en vergeet niet uw controles op te nemen.',
    guardar: 'Opslaan',
    ensayo: 'laboratorium test',

  },
  ensayos: {
    no_sub_options: 'Geen subopties',
    no_results: 'Geen resultaten gevonden...',
    configCountResults: 'Telresultaten instellen',
    definirEnsayos: 'Labtestbereiken definiëren',
    rangosLeyenda: 'De bereiken definiëren die de labtest zal hebben',
    rango1: 'Bereik 1',
    rango2: 'Bereik 2',
    Rowsperpage: 'Rijen per pagina:',
    viewunitsofmeasure: 'Maateenheden weergeven',
    idioma: 'Taal',
    Addconditional: 'Conditionering toevoegen',
    Assignunitsofmeasure: 'Maateenheden toewijzen',
    Testname: 'Testnaam',
    Testcategory: 'Testcategorie',
    Status: 'Status',
    Setuptestparameters: 'Testparameters instellen',
    Setupandaddrelevant:
      'Deze test instellen en relevante informatie over de detectieparameters van deze test toevoegen',
    Basicinformation: 'Basisinformatie',
    Pleasefill: 'Voer de gevraagde informatie in om uw test aan te maken',
    Classesoutofspec: 'Klassen buiten de spec.',
    Selectcategory: 'Categorie selecteren',
    Addcondition: 'Voorwaarde toevoegen',
    Informationrequired: 'Gegevens vereist',
    warning: 'Waarschuwing',
    ConfigurarEnsayo: 'Labtestinstellingen',
    Matrices: 'Matrix',
    clasesnocumplen: 'Klassen die niet voldoen aan',
    Clases: 'Klassen',
    Addthresholds: 'Drempelwaarden toevoegen',
    cusromparam: 'Parameters bewerken',
    labs: 'Laboratoria',
    costo: 'Kosten',
    cod_ensayo: 'Testcode',
    tiempo_result: 'Tijd om resultaten te verkrijgen (uur)',
    activite: 'TAAG-testen activeren',
    active_lab: 'TAAG-laboratoriumtesten activeren',
    txt_kit: 'Voeg laboratoriumtests toe die compatibel zijn met de real-time PCR-kits van TAAG Genetics.',
    add: 'Toevoegen',
    Addparameter: 'Parameter toevoegen',
    bajo: 'Laag',
    alto: 'Hoog',
    alto_riesgo: 'Hoog Risico',
    bajo_riesgo: 'Laag Risico',
    pathogeno: 'Pathogeen',
    indicador: 'Indicator',
    deteriorante: 'Bederforganisme',
    cerrar: 'Sluiten',

    // modal- param condition
    txt_param_condition: 'Parametervoorwaarden',
    t_param: 'Parameter',
    classifitacion: 'Classificatie',
    RiskLevel: 'Risiconiveau',
    you_param: 'Uw parameters',
    add_param: 'Parameternaam',

    // modal-tipo ensayo

    txt_title: 'Parameters instellen',
    test: 'Test',
    tezt_category: 'Testcategorie',
    tipo_test: 'Testnaam typen',
    category: 'Testcategorie selecteren',

    // modal-configuracion recuento

    title: 'Instellingen voor telresultaten',
    title2: 'Selecteer de cijfernotatie waarin uw telresultaten worden gerapporteerd.',

    // txt- tooltpis
    txt_namerepot: 'Deze naam verschijnt in het rapport.',
    txt_t_ensayo:
      'Hier selecteert u een testtype of maakt u er een aan, dat wil zeggen moleculaire biologie, traditionele testen, enz.',
    txt_desc: 'Deze beschrijving verschijnt in het rapport.',
    txt_metho: 'Deze methodologie verschijnt in het rapport.',
    txt_param:
      'Dit is wat uw test zal detecteren, bv. Staphylococcus aureus, Kopergehalten, Totale colibacteriën, enz. U kunt zoveel parameters toevoegen als u wilt, afhankelijk van de beperkingen van uw test.',
    txt_t_tecnica:
      'Als u Traditionele microbiologie, Moleculaire testen of Sneltesten selecteerde, willen wij graag dat u aanvullende informatie over uw parameters toevoegt, zoals risiconiveau of classificatie, zodat we onze algoritmen kunnen verfijnen voor een optimale analyse.',
    txt_result: 'Zo vormt u uw resultaten.',

    ensayos_lab: 'Laboratoriumtesten',
    crear_edita: 'Laboratoriumtesten aanmaken, bewerken en beheren',
    buscar: 'Zoeken',
    crear_nuevo_ensayo: 'Een nieuwe labtest toevoegen',
    mantebedor: 'Matrixbeheerder',
    resumen: 'Samenvatting van de test',
    ningun_registro: 'Geen record gevonden',
    r_pagina: 'Record per pagina',
    inactivo: 'Inactief',
    activo: 'Actief',
    detalle: 'Details bekijken',
    mantenedor: 'Parameterbeheerder',
    crear_param: 'Parameters aanmaken',
    guardar: 'Opslaan',
    guardar_ensayo: 'Test opslaan',
    param_manager: 'Parametermanager',
    nombre: 'Testnaam',
    tipo: 'Type',
    param_mtz: 'Parameters en matrices',
    option: 'Optie',
    creado: 'Aanmaakdatum',
    clasif: 'Classificatie',
    rules_txt0: 'Testnaam is verplicht',
    rules_txt1: 'Testtype is verplicht',
    rules_txt2: 'Methodologietype is verplicht',
    rules_txt3: 'Site-analyse is verplicht',

    rules_txt4: 'Het product is verplicht',
    rules_txt5:
      'U moet ten minste één parameter selecteren. Als er geen parameters beschikbaar zijn in de vervolgkeuzelijst, moet u ze toevoegen in de parametermanager.',
    rules_txt6: 'Het resultaattype is verplicht',
    rules_txt7: 'Het kittype is verplicht',
    rules_txt8: 'De beschrijving van de labtest is verplicht.',
    rules_txt9: 'De methodologie is verplicht',
    rules_txt10: 'U moet een bedrijf selecteren',
    rules_txt11: 'Resultaattijd is verplicht ',
    rules_txt12: 'Technisch type is verplicht',
    rules_txt13: 'De naam van de proef is verplicht',

    rules_txt14: 'Drempelwaarde is verplicht',
    rules_txt15: 'Monstertype is verplicht',
    rules_txt_condicionparam: 'Geselecteerde parameters en voorwaarden',

    // modal
    param_matrices: 'Parameters en matrices',
    matrices: 'Matrix',
    param: 'Parameter',
    editar: 'Bewerken',
    cancelar: 'Annuleren',

    // cierre modal

    agregar_ensayo: 'Nieuwe laboratoriumtest toevoegen',
    nombre_ensayo: 'Testnaam',
    estado: 'Teststatus',
    tipo_ensayo: 'Testtype',
    Name_tipo_ensayo: 'Testtypenaam',
    info_tecnicas: 'Technische informatie over uw labtest',

    clasif_ensayo: 'Proefclassificatie',
    desc: 'Beschrijving (max. 200 tekens)',
    testnamea: 'Testnaam',
    activado: 'Actief',

    metodologia: 'Methodologie',
    txt1: 'Niet gevonden',
    txt2: 'Druk op Enter om aan te maken.',
    tipo_tecnica: 'Parameterspecificatie',
    tipo_resultado: 'Resultaattype',
    asignacion: 'Matrices toewijzen',
    select: 'Het type monsters / matrices selecteren dat met deze test wordt geanalyseerd',
    unidades: 'Maateenheidtype',
    thparam: 'Parameter',
    thumbral: 'Drempelwaarde',
    thunidad: 'Eenheden',

    definir: 'Detectiedrempelwaarden en maateenheden definiëren',
    selectumbrales: 'Het type monsters / matrices selecteren dat met deze test wordt geanalyseerd',
    umbral: 'Drempelwaardetype',
    unidad_medida: 'Maateenheden',
    alimento: 'Voeding',
    superficies: 'Oppervlakken',
    manipuladores: 'Hanteerders',
    aguas: 'Water',
    ambientes: 'Omgevingen',
    txt3: 'Drempelwaarden zijn niet van toepassing op het resultaattype \'Afwezigheid / Aanwezigheid\'',

    PA: 'Aanwezigheid Afwezigheid',
    RTO: 'Telling',
    Ext: 'Extern getoond',

    rules_txt_condicion: 'Geselecteerd niveau en geselecteerde classificatie',
    update_succes: 'Met succes bijgewerkt',
    save_succes: 'Met succes opgeslagen',
    selectedmatriz: 'Een matrix selecteren',
    delete_param: 'Parameter verwijderd',
    save_param: 'Opgeslagen parameter',
    // modal eliminar ensayo
    delete_title: 'Waarschuwing',
    delete_cuestion: 'Wilt u deze test echt verwijderen? \n',
    delete_cuestion2: 'Dit kan niet ongedaan worden gemaakt.',
    activekits: 'Actieve kits Taag',
    listakits: 'Lijst met kits',

    umbralesalimento: 'Niet-gedefinieerde drempelwaarden in voeding',
    umbralessuperficie: 'Drempelwaarden niet gedefinieerd in oppervlak',
    umbralesmanipuladores: 'Niet-gedefinieerde drempelwaarden in hanteerders',
    umbralesagua: 'Niet-gedefinieerde drempelwaarden in water',
    umbralesambiente: 'Niet-gedefinieerde drempelwaarden in omgevingen',
  },
  matrices: {
    Alimento: 'Voeding',
    Alimentos: 'Voeding',
    Aguas: 'Water',
    Agua: 'Water',
    Superficies: 'Oppervlak',
    Manipuladores: 'Hanteerders',
    Ambientes: 'Omgeving',
  },
  modal_tipo_ensayo: {
    TradicionalMicrobiology: 'Traditionele microbiologie',
    MolecularTesting: 'Moleculaire testen',
    RapidTesting: 'Sneltesten',
    ChemicalTesting: 'Chemische testen',
    Other: 'Andere',
  },
  tipoResul: {
    PresenceAbsence: 'Aanwezigheid afwezigheid',
    Count: 'Telling',
    Exeternalized: 'Invoer openen',
  },
  submatriz: {
    MateriaPrima: 'Grondstof',
    ProductoTerminado: 'Eindproduct',
    SemiElaborado: 'Half uitgewerkt',
    zona1: 'zone 1',
    zona2: 'zone 2',
    zona3: 'zone 3',
    critica: 'Kritiek',
    nocritica: 'Niet-kritiek',
    presanitizacion: 'Voorontsmetting',
    postsanitizacion: 'Na-ontsmetting',
    enuso: 'In gebruik',
    guante: 'Met handschoenen',
  },
  solicitudes: {
    fechaasignado: 'Toegewezen datum',
    Horainicio: 'Begintijd',
    Horatermino: 'Eindtijd',
    Success: 'Succes',
    Eventoeliminadocorrectamente: 'Gebeurtenis met succes verwijderd',
    Advertencia: 'Waarschuwing',
    Deseaeliminarestasolicitud: 'Wilt u deze gebeurtenis verwijderen',
    aceptar: 'Accepteren',
    Max200characters: 'Max. 200 tekens',
    fecha: 'Datum',
    horainicio: 'Begintijd',
    horatermino: 'Eindtijd',
    Asunto: 'Onderwerp',
    Descripcion: 'Beschrijving',
    Eliminar: 'Verwijderen',
    Editar: 'Bewerken',
    Ingresarcomentarionomayora200caracteres: 'Een opmerking van maximaal 200 tekens invoeren',
    success: 'Succes',
    success1: 'De wijzigingen zijn correct aangebracht',
    mostrar: 'Aanvragen weergeven',
    ocultar: 'Aanvragen verbergen',
    ingresada: 'Ingevoerde aanvragen',
    cliente: 'Klant',
    planta: 'Installatie',
    muestreador: 'Monsternemer',
    fecha_creacion: 'Aanmaakdatum',
    fecha_programada: 'Geplande datum',
    estado: 'Status',
    detalle: 'Details',
    encargado: 'Lokale manager',
    fecha_presunta: 'Vermoedelijke datum',
    dir: 'Adres',
    materia_prima: 'Grondstof',
    f_elab: 'Uitwerkingsdatum',
    f_ven: 'Vervaldatum',
    parametros: 'Parameters',
    opciones: 'Opties',
    // modalensayo
    title_ensayo: 'UIT TE VOEREN LABTEST',
    muestra: 'Monster',
    n_ensayo: 'Testnaam',
    desc: 'Beschrijving',
    lote: 'Partij',
    revisar: 'Controleren',

    // modaledit
    title_edit: 'Monsterbewerker',

    // coordinacion
    coo: 'Lijst met aanvragen',
    ordenar: 'Sorteren op',
    total_soli: 'TOTALE AANVRAGEN',
    hoy: 'Vandaag',
    dia: 'Dag',
    semana: 'Week',
    mes: 'Maand',
    dias4: '4 Dagen',

    // MODAL COORDINACION
    EditarEvento: 'Gebeurtenis bewerken',
    crear_evento: 'Gebeurtenis aanmaken',
    asunto: 'Onderwerp',
    resumen_solicitado: 'Aangevraagde samenvatting',
    direccion: 'Adres',
    resumen_muestra: 'Monstersamenvatting',
    cancelar: 'Annuleren',
    obvs: 'Waarnemingen',
    codigo_barras: 'codebalk',
    DetallesolicitudID: 'Aanvraag-ID-details',
    Detallesolicitud: 'Aanvraagdetails',
    deletesoli: 'Aanvraag verwijderen',
    Editarsolicitud: 'Aanvraag bewerken',
    servicio: 'Service',
    guardar: 'Opslaan',
    Desearealizarloscambios: 'U wilt de wijzigingen aanbrengen',
    region: 'regio',
    manipuladores: 'Hanteerders',
    agua: 'Water',
    ambientes: 'Omgevingen',
    superficies: 'Oppervlakken',
    alimentos: 'Voedingsmiddelen',
  },
  uploadfile: {
    ControldeVersiones: 'Versiebeheer',
    Clientes: 'Klanten',
    Todos: 'Alle Klanten',
    Ensayo: 'Labtest',
    versionactualizada: 'Versie bijgewerkt',
    Zipfile: 'Zipbestand',
    Version: 'Versie',
    NombredelCarpetaprincipal: 'Naam van de bovenliggende map',
    Nombredelarchivoprincipal: 'Naam van het hoofdbestand',
    Clear: 'Wissen',
    Submit: 'Verzenden',
    Historial: 'Geschiedenis',
    showall: 'Alles weergeven',
    Matrices: 'Matrix',
    Archivo: 'bestand',
    Ruta: 'pad',
    RutaBackup: 'Back-uproute',
    Activo: 'Actief',
    Creado: 'Aangemaakt',
    Cliente: 'Klant',
    Ensayos: 'Labtesten',
  },
};

export default nl;
