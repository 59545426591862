import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    lang: null,
    translate: {},
  },
  mutations: {},
};
