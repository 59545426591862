import Vue from 'vue';
import Vuex from 'vuex';
// import _ from 'lodash';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    EnsayosDataStore: [],
    HiddenSolicitudDetailsStore: null,
    RemoveListRequestItemStore: null,
    FormatDateStore: null,
  },
  mutations: {
    fnSetEnsayosDataStore(state, data) {
      state.EnsayosDataStore = data;
    },
    fnHiddenSolicitudDetailsStore(state, data) {
      state.HiddenSolicitudDetailsStore = data;
    },
    fnRemoveListRequestItemStore(state, data) {
      state.RemoveListRequestItemStore = data;
    },
    fnFormatDateStore(state, data) {
      state.FormatDateStore = data;
    },
  },
};
