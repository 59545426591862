<style lang="scss">
/* eslint-disable */
  @import "./assets/sass/global.scss";
  body{
    --width: 50;
    --height: 80;
    --padding-top: 60;
    --padding-bottom: 60;
    --padding-wrapper: 15;
    font-family: 'Open Sans', sans-serif !important;
  }
  .o-toggle-modal__li  {
    svg{
      width: 18px;
      //height: 18px;
      margin: 0.5rem auto;
      &.svg-menu{
          --svgcolor: #333;
          margin: auto;
          height: 22px !important;
        &__dashboard,
        &__home
        {
          --svgcolor: #333;
          width: 22px!important;
          height: 22px!important;
        }
        &__cerrarSesion{
          --svgcolor: #333;
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  .o-toggle-modal{
    &__li{
      //padding: 0.65rem 0rem!important;
      &:hover{
          background-color: #BDBDBB !important;
        svg {
            --svgcolor: white;
        }
      }
    }
  }
  .o-barra-superior {
    font-weight: lighter;
    font-weight: 300;
    background-color: #000;
    color: #fff;
    text-align: left;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 11 !important;
    padding: 0 12px !important;
    font-size: 16px;
    display: grid;
    height: calc( var(--height) * 1px );
    // padding: 0 1.5rem !important;
    padding: 0 1.0rem !important;
    strong {
      font-weight: bold;
      font-weight: 600;
    }
  }
  .header-slide {
    padding: 4rem 0;
    justify-content: center;
  }

  #app {
    .v-navigation-drawer{
      background-color: #ffffff;
      visibility: visible;
      height: 100vh;
      top: 0px;
      transform: translateX(0%);
      // width: 250px !important;
      max-height: calc(100% - 0px);
      transition: all 0.25s ease;
      &--open{
        transform: translateX(0px) !important;
      }
      &--close{
        transform: translateX(calc(var(--width) * -1px)) !important;
        & + #contenedor_btn_principal #wrapper{
          padding-left: calc( var(--padding-wrapper) * 1px );
        }
      }
      &--clipped{
        &:not(.v-navigation-drawer--temporary){
          &:not(.v-navigation-drawer--is-mobile) {
            z-index: 99;
          }
        }
      }
      -webkit-overflow-scrolling: touch;
      display: flex;
      flex-direction: column;
      left: 0;
      max-width: 100%;
      overflow: hidden;
      pointer-events: auto;
      top: 0;
      transition-duration: 0.2s;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      will-change: transform;
      transition-property: transform, visibility, width;
      &--fixed {
        position: fixed;
      }
      &--mini-variant {
        overflow: visible !important;
      }
      &__content {
        padding-top: calc( var(--height) * 1px );
      }
      &__content {
        height: 100%;
        overflow-y: initial;
        overflow-x: initial;
      }
      &__content {
        background-color: #dcdee0;
        background-color: #dcdee05e;
      }
      & .o-toggle-modal__span{
        position: absolute;
        background-color: white;
        padding: 0.25rem 1.0rem;
        vertical-align: middle;
        display: inline-block;
        margin-left: 1rem;
        width: max-content;
        border-radius: 3px;
        box-shadow: 2px 1px 3px #a9a9a961;
        transition: all 0.25s ease-out;
        transform: translate(-75%, 0) scale3d(0, 0, 0);
        z-index: 5;
        opacity: 0;
      }
      a{
        text-align: center;
        padding: 0;
      }
      & .o-toggle-modal__li{
        // padding: 0;
        &:hover {
          & .o-toggle-modal{
            &__span{
              transform: translate(0%, 0) scale3d(1, 1, 1);
              z-index: 1;
              opacity: 1;
              left: 60%;
            }
          }
        }
        a{
          text-align: center;
          display: inline-flex;
          padding: 0;
        }
      }
    }
  }
  span.o-toggle-modal__span::before {
      content: "";
      background: white;
      width: 10px;
      height: 10px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate3d(-50%, -50%, -100px) rotate(45deg);
      box-shadow: -1px 0px 3px #a9a9a961;
      z-index: -1;
  }
  #sideNavigation,
  .o-navbar-hamb
  {
      display: none;
  }
  .c-analysis-to-perform__modal-body.scroll {
      min-height: 400px;
      max-height: calc(100vh - 230px);
  }
  .table-responsive{
      overflow-x: initial;
  }
  .o-logout{
    .v-list-item{
      padding: 0;
    }
  }
  .v-list-item--, .v-list-- .v-list-item {
    min-height: 30px !important;
  }
  .v-application--is-ltr .v-list-item__avatar:first-child{
      margin: 0;
  }
  #app .o-txa-logo {
      padding-top: .5rem !important;
      padding-top: 0 !important;
      padding-bottom: 1px !important;
  }

  // .v-list-item-- .v-list-item__content, .v-list-- .v-list-item .v-list-item__content {
  //     padding: 8px 0;
  // }

  .v-navigation-drawer .m-logo .v-list-item__content {
    padding: 0 2px !important;
  }

  .v-list-item__content > *:not(:last-child) {
    margin-bottom: 0;
  }
  .v-navigation-drawer .menu-hover:hover,.v-navigation-drawer .menu1-hover {
    border-radius: 6px;
  }

  #app .v-navigation-drawer a{
    margin: 13px 0px!important;
  }

  .v-label .theme--light{
    font-size: 12px ;
  }

  .v-text-field.v-text-field--solo.v-input-->.v-input__control {
      min-height: 25px;
  }
  .v-text-field.v-text-field--solo .v-label {
      font-weight: 400 !important;
  }

  .style-chooser .v-input__control,
  .style-chooser .v-input__slot,
  .style-chooser .v-label.theme--light{
    background: black !important;
    color: white !important;
  }
  .style-chooser .v-label.theme--light .v-select__selection--comma{
      color: white !important;
    }
  .style-chooser .v-select__selection {
    color: white !important;
  }
  .style-chooser .v-select__selection--comma{
    color: white !important;
  }
  .style-chooser .theme--light.v-icon {
      color: white;
  }
  .style-chooser .vs__clear,
  .style-chooser .vs__open-indicator {
    fill: #394066;
  }
  .theme--light.v-list-item:hover .v-icon.v-icon {
    color: #808080;
  }
  .pt-60{
    padding-top: 54px !important;
  }
  .pb2r{
    padding-bottom: 1.5rem;
  }
  .b-bottom-gray{
    border-bottom: 1px solid #CACACA;
  }
  .fs12{
    font-size: 12px !important;
  }
  .fs15{
    font-size: 15px !important;
  }
  .fs18{
    font-size: 18px !important;
  }
  .justify-flex-end{
    justify-content: flex-end;
  }
  .pl16{
    padding-left: 27px;
  }
  .list-side:hover{
    background: #CECECE;
  }
  .main-pl{
    padding-left: 250px !important;
  }
  .v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
    cursor: pointer;
  }
  .v-label.theme--light {
      font-size: 14px !important;
  }

  .theme--light.v-list-item .tooltip_menu{
    padding: 5px 0!important;
  }

  .flex-item-center-justify-content-flex-end{
      display: flex;
      align-items: center;
      justify-content: flex-end;
  }

  .style-chooser .v-menu__content .theme--light .menuable__content__active {
      box-shadow: none !important;
      border-radius: 0px !important;
      border: 1px solid !important;
      min-width: 88px !important;
      top: 49px !important;
      left: 91% !important;
  }
  .c-white{
    color: white;
  }
  .text-sidebar{
    font-size: 13px !important;
    color: white;
    font-weight: 400px !important;
  }
  .settings-hover{
    border-radius: 6px;
    height: auto !important;
    .v-list-item__content {
      height: 33px !important;
    }
  }
  .settings-hover:hover{
    background-color: #DAECFF !important;
  }
  .settings-hover:hover p{
    color: #007AFF !important;
  }
  .settings-hover:hover svg.svg_settings{
    filter: invert(32%)
    sepia(98%) saturate(2561%) hue-rotate(199deg) brightness(99%) contrast(111%);
  }
  .svg_settings{
    --svg-stroke: #888;
    stroke: var(--svg-stroke);
  }
  .align-item-center{
    align-items: center;
  }
  .justify-content-space-between{
    justify-content: space-between;
  }
  .t-span{
      font-size: 13px !important;
      margin-left: 8px;
      padding-bottom: 5px !important;
  }
  .menu-active{
    background-color: #007AFF !important;
    cursor: pointer;
    border-radius: 10px;
  }
  .menu-active svg.svg-icon{
    --strokeColor: #fff;
  }
  .menu-hover:hover,
  .menu1-hover, .menu-pcr{
    background-color: #007AFF !important;
    cursor: pointer;
    border-radius: 10px;
  }
  .menu-hover:hover svg.svg-icon, .menu-pcr svg.svg-icon{
    --strokeColor: #fff;
  }
  svg.svg-icon{
    transition: none !important;
    --strokeColor: #888;
    stroke: var(--strokeColor);
    // fill: var(--strokeColor);
    // width: 22px !important;
    // height: 22px !important;
    width: 24px !important;
    max-width: 24px !important;
    height: 26px !important;
    margin: auto!important;
  }
  .menu-hover:hover .t-span{
    color: #fff;
  }
  .margin-logo{
      margin-bottom: 1.2rem !important;
      margin-left: 2px !important;
      margin-top: 11px;
  }
  .pt2{
    padding-top: 2px !important;
  }
  .padding-setting{
    padding-left: 20px !important;
    padding-top: 4px !important;
  }
  .pl15{
    padding-left: 15px !important;
  }
  .padding-reg{
    padding-right: 14px;
    /* padding-top: 6px; */
    padding-top: 5px;
  }
  .mt12{
    margin-top: 13px !important;
  }
  .pl12{
    padding-left: 12px !important;
  }
  .v-list-item--active {
      background: #e7e8e9 !important;
  }
  .h42{
    height: 42px !important;
  }
  .text-last-center{
      text-align-last: center !important;
  }
  .padding5px{
      padding: 5px !important;
  }
  .text-transform{
      font-size: 14px !important;
      text-transform: uppercase;
      color: black !important;
  }
  .btn--exit:hover{
    background-color: transparent !important

  }
  .fs13{
    font-size: 13px !important;
  }
  .p0{
    padding: 0 !important;
  }
  .v-menu__content .theme--light .menuable__content__activ.width-content-info{
    width: 395.219px !important;
  }

  .ddemail{
    margin-bottom: 12px !important;
    padding-top: 10px !important;
  }
    .tooltip_header {
      position: relative;
      display: inline-block;
      cursor: pointer;
    }
    .tooltip_header .tooltiptext_header {
      font-size: 12px;
      visibility: hidden;
      width: 350px;
      background-color: white;
      border-radius: 5px;
      box-shadow: 5px 1px 15px -4px rgba(102,102,102,0.94);
      color: #666666;
      text-align: left;
      padding: 15px 15px;

      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      bottom: -133px;
      left: -334px;
    }
    .tooltip_header:hover .tooltiptext_header {
      visibility: visible;
    }
    .a-button{
      color: white !important;
      background-color: black;
      padding: 0.4rem 1rem;
      border-radius: 5px;
      margin: 7px 0;
    }
    .content-text2 {
      font-size: 12px;
      background: #808080 !important;
      text-align: left;
      padding: 15px 0px;
      // -webkit-box-shadow: 5px 5px 15px 5px #00000038;
      box-shadow: 5px 5px 15px 5px #00000038;
      // /* Position the tooltip */
      position: absolute;
      z-index: 9999;
      bottom: 42px;
      width: 100%;
      left: 0;
    }
    .pl2rem{
      padding-left: 14px !important;
    }
    .txt-span{
      color: #ffffff;
      font-size: 13px !important;
    }
    .p-2{
      padding: 0.5rem!important;
    }
    .divhover:hover{
      background-color: #666666;
  }
  .v-select__slot {
      align-items: center !important;
  }
  .separador{
    background-color: #888888 !important;
    /* border: 1px solid #888888; */
    margin: 0 10px !important;
  }
  .content-setting{
    .v-select.v-text-field input {
      font-size: 14px !important;
    }
    .text-field--enclosed.v-input--:not(.v-text-field--solo).v-text-field--outlined
    .v-input__append-inner{
      margin-top: 5px;
    }
  }
  .padding-left-custom{
    padding-left: 200px !important;
  }

  .sidebar2{
    z-index: -1;
    transform: translateX( var(--anchoCaja) );
    transition: all 0.5s ease;
  }
  .select-idioma{
    margin-right: 29px !important;
    max-width: 110px !important;
    height: 31px;
  }
  #app .v-application--is-ltr .v-text-field .v-input__append-inner {
    margin-top: 0 !important;
    }
  .v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
    z-index: 9999 !important;
  }

  .sidebar2{
    .v-list-item{
      &.active{
        .v-list-item__content{
          background-color: #DAECFF !important;
        }
        p {
          color: #007AFF !important;
        }
        svg{
          --svg-stroke: #007AFF;
          filter: invert(32%)
          sepia(98%) saturate(2561%) hue-rotate(199deg) brightness(99%) contrast(111%);
        }
      }
    }
    .v-text-field--outlined.v-input--dense.v-text-field--single-line
     > .v-input__control > .v-input__slot,
    .v-text-field--outlined.v-input--dense.v-text-field--outlined
     > .v-input__control > .v-input__slot {
        min-height: initial;
    }
  }

  .settings_class, .ots_class {
    &.active{
      .tooltip_menu{
        background-color: #007AFF !important;
        cursor: pointer;
        border-radius: 6px;
      }
      svg.svg-icon {
          --strokeColor: #fff;
      }
    }
  }
  .settings_class{
    padding-bottom: 1rem!important;
    &:before{
      border-bottom:1px solid #888888;
      background-color: transparent!important;
      opacity: 1!important;
      // width: calc(100% - 10px);
      width: calc(100% - 20px);
      margin: auto;
      bottom: 2px!important;
    }
  }
  .ots_class{
    // padding-top: 1rem!important;
  }

/* Submenu */
.menubar {
  &.settings-hover{
    #app &{
      margin: 7.5px 0px!important;
      padding: 5px 0!important;
      padding-left: 18px!important;
    }
  }
  .svg_settings {
    margin-right: 10px;
  }
  p{
    font-size: 13.5px !important;
    line-height: 20.5px;
  }
}
.sidebar2{
  .content-setting{
    padding-left: 10px!important;
    padding-right: 10px!important;
  }
}
/* Language Translate */
.o-language {
  .v-text-field.v-text-field--solo.v-input--dense > .v-input__control{

    box-shadow: none;
    height: initial;
    padding: 0!important;
    color: #333!important;
  }
  & .v-text-field--outlined fieldset {
    #app &{
      height: initial!important;
      border:none!important;
      background-color: transparent;
    }
  }
  & .v-select__slot,
  & .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    #app &{
      min-height: initial;
    }
  }
  & .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control > .v-input__slot{
    #app &{
      padding: 0;
      background: transparent;
    }
  }
  .theme--light.v-input input{
    &::placeholder{
      color:#333;
    }
  }
  .mdi-menu-down::before {
    content: "\F0140";
  }
}
.mt50 {
    margin-top: 50px;
}
.mb-50 {
    margin-bottom: 50px;
}
.mb-48 {
    margin-bottom: 48px;
}

.v-list-item{
  &.active{
    .tooltip_menu{
      border-radius: 6px;
      background-color: #007AFF !important;
      .svg-icon{
        --strokeColor: #fff;
      }
    }
  }
}

/* Lenguaje */
#app #languageBtn{
    margin: auto!important;
}
.tooltip_menu{
    overflow: visible!important;
}

.d-lenguaje{
  .style-chooser .v-input__control,
  .style-chooser .v-input__slot,
  .style-chooser .v-label.theme--light {
      background: transparent !important;
  }
  .select-idioma{
    margin-right: 5px !important;
    height: 36px;
  }
  .v-select__selections{
    height: 100%;
  }
  .v-select__selection{
    color: black !important;
  }
}

.v-navigation-drawer__content {
    background-color: #fff;
    overflow: visible;
}
.content--nav__menu {
    overflow: visible;
}
.o-toggle-modal__ul {
    overflow: visible;
}
.d-lenguaje option {
    background-color: orange;
}
.d-lenguaje {
    position: absolute;
    left: 110%;
    top: 0;
    width: 120px;
    background: white;
    padding: 10px;
    border-radius: 5px;
    z-index: 999999;
    padding: 6px 6px;
    box-shadow: -3px 3px 6px rgb(128 128 128 / 20%);
}
.d-lenguaje:before {
    position: absolute;
    content: "";
    border: 6px solid transparent;
    border-right-color: white;
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%);
}
.d-lenguaje .dropdown-toggle{
    border: 1px solid #ccc;
    margin: 0px !important;
    border-radius: 5px!important;
    background-position: 90% 50%;
}
.d-lenguaje .dropdown-menu {
    padding: 0;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%),
    0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
    transform: translate3d(0px, 0px, 0px)!important;
}
.d-lenguaje .dropdown-menu li {
    height: 42px!important;
    margin: 0;
}
.d-lenguaje .dropdown-menu li a{
    display: flex;
    align-items: center;
    padding-left: 5px;
    height: 100%;
    font-weight: 500;
}
.d-lenguaje .dropdown-menu li.active {
    color: #007AFF!important;
    caret-color: #007AFF!important;
    border-left: 3px solid #2d00d7;
    background: #FCF8FF;
    border-radius: 3px;
}
.d-lenguaje .dropdown-menu li.active span{
    color: #007AFF!important;
}
.d-lenguaje .dropdown-menu li.active:before {
    background-color: currentColor;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: .3s cubic-bezier(.25,.8,.5,1);
    opacity: .12;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: initial;
}
.v-input select{
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    font-size: 14px !important;
    letter-spacing: normal;
    max-width: 100%;
    text-align: left;
    padding-left: 10px!important;
    background-position: 90% 50%!important;
}
</style>

<template>
<!-- eslint-disable -->
  <div  id="app" >
    <v-app id="inspire">
      <v-container class="o-barra-superior" fluid v-if="false">
        <v-row  style="display: flex; justify-content: space-between;">
          <v-col style="display: flex; align-items: center;" cols="10">
            <p class="mb-0 mr-1" style="margin-left: 7px !important;">
              <strong>TAAG </strong></p> <span class="normal">Genetics</span>
          </v-col>
          <v-col class="flex-item-center-justify-content-flex-end pt-3" cols="2">
              <div  class="mt-3 mr-2 tooltip_header">
                <svg style="width:20px;height:20px" viewBox="0 0 24 24">
                  <path fill="white" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7.07,18.28C7.5,17.38 10.12,16.5 12,16.5C13.88,16.5 16.5,17.38 16.93,18.28C15.57,19.36 13.86,20 12,20C10.14,20 8.43,19.36 7.07,18.28M18.36,16.83C16.93,15.09 13.46,14.5 12,14.5C10.54,14.5 7.07,15.09 5.64,16.83C4.62,15.5 4,13.82 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,13.82 19.38,15.5 18.36,16.83M12,6C10.06,6 8.5,7.56 8.5,9.5C8.5,11.44 10.06,13 12,13C13.94,13 15.5,11.44 15.5,9.5C15.5,7.56 13.94,6 12,6M12,11A1.5,1.5 0 0,1 10.5,9.5A1.5,1.5 0 0,1 12,8A1.5,1.5 0 0,1 13.5,9.5A1.5,1.5 0 0,1 12,11Z" />
                </svg>
                <span class="tooltiptext_header">
                  <div class="py-2 text-center">
                    <p class="c-black fs14 mb-0 text-nowrap font-weight-bold">{{nombre_cuadro}}</p>
                  </div>
                  <div class="text-center">
                    <p class="c-black fs13 mb-0 text-nowrap">{{email_cuadro}}</p>
                  </div>
                  <div class="text-center">
                    <a key="exit" @click="salir()" type="button"  class="a-button text-center">
                      <div class="d-flex">
                        <span class="fs13 pr-2">{{ $t('menu.salir') }}</span>
                          <svg style="width:20px; height:20px" viewBox="0 0 24 24">
                          <path fill="white" d="M16,17V14H9V10H16V7L21,12L16,17M14,2A2,2 0 0,1 16,4V6H14V4H5V20H14V18H16V20A2,2 0 0,1 14,22H5A2,2 0 0,1 3,20V4A2,2 0 0,1 5,2H14Z" />
                        </svg>
                      </div>
                    </a>
                  </div>
                </span>
              </div>
              <a href="#" class="mt-3">
                <svg style="width:20px;height:20px" viewBox="0 0 24 24">
                  <path fill="#ffffff" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                </svg>
              </a>
              <v-select
                class="pt-1 style-chooser"
                style=" max-width: 110px !important;"
                item-text="title"
                item-value="locale"
                :items="languages"
                :label="labelLang"
                solo
                @input="changeLanguage($event)">
              </v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-app id="sandbox" >
          <v-navigation-drawer
            v-model="primaryDrawer.model"
            :clipped="true"
            :mini-variant="true"
            :temporary="primaryDrawer.type === 'temporary'"
            permanent
            app
            overflow
            style=""
          >
            <v-list class="m-logo" >
              <v-list-item class="o-txa-logo">
                <v-list-item-content class="h-auto">
                  <div class="box">
                      <!--
                        <img width="47" height="47" src="/img/l-txa.png">
                      -->

                      <img width="auto" height="47" src="/img/nuevologotxa.png">
                    <!-- <span class="pt2 ml-5">TAAG Xpert Assistant</span> -->
                  </div>
                </v-list-item-content>
                <v-btn
                  icon
                  @click.stop="mini = !mini"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </v-list-item>
            </v-list>

            <!-- menu de txa  -->
            <v-list  v-if="false" style="margin-top: 22px;">
              <v-list-item
                v-for="item in navmenu"
                :key="item.title"
                :href="item.href"
                :icon="item.icon"
                :disabled="item.active || !item.module_active"
                :style="item.module_active ? '': 'display:none'"
              >
                <v-list-item-content class="menu-hover">
                    <svg style="width: 42px !important; height: 22px !important;">
                        <use :xlink:href='`/img/icons/icons.svg#${ item.icon }`'></use>
                    </svg>
                    <span class="t-span">{{ item.title }}</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <!-- fin menu txa -->
            <!-- menu de setting -->
            <!-- <v-divider class="separador"></v-divider> -->
            <v-list >
              <!-- iteramos json base -->
              <v-list-item
                v-for="item in menuBase.items"
                :key="item.key"
                :href="item.url"
                :icon="item.icon"
                v-if="item.show && item.active"
                :class="[
                    { 'settings_class' : item.icon == '/img/icons/icons.svg#settings' },
                    { 'active': item.icon == '/img/icons/icons.svg#settings' && showanchocaja == true && (sidebar_global == 'Settings' || sidebar_global ==  '/Ensayos' || sidebar_global ==  '/ensayos' ) },

                    { 'ots_class' : item.icon == '/img/icons/icons.svg#preOt' },
                    { 'active': item.icon == '/img/icons/icons.svg#preOt' && showanchocaja == true &&  sidebar_global == 'WorkOrdersyRequests'},
                    { 'active': checkItemURL(item) },
                    ]"
                @click.prevent="showSubmenu(item)"
                class="my-3"
              >
                <v-list-item-content class="tooltip_menu menu-hover py-2" >
                    <svg class="svg-icon">
                        <use :xlink:href='`${ item.icon }`'></use>
                    </svg>
                    <span class="tooltiptext_menu">{{ item.module }}</span>
                </v-list-item-content>
              </v-list-item>
              <!-- fin json base -->
            </v-list>
            <!-- fin menu settings -->

            <!-- inicio submenu this.showanchocaja_ot = false;-->
            <div  class="sidebar2" :style="`--anchoCaja:${(showanchocaja) ? '0px' : '-203px'};`">
              <div class="mt50 mb-48 d-flex align-item-center
              justify-content-space-between">
                <p class="text-left my-0 py-0 bold padding-setting fs18">{{ (sidebar_global == 'WorkOrdersyRequests') ? $t('submenu.Workordersandrequests') : $t('menu.setting') }}</p>
                <span class="my-0 py-0 padding-reg c-pointer" @click.prevent="sidebar_close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14.414 12.828">
                      <g id="chevrons-left" transform="translate(-5 -5.586)">
                        <path id="Trazado_40956" data-name="Trazado 40956" d="M11,17,6,12l5-5" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <path id="Trazado_40957" data-name="Trazado 40957" d="M18,17l-5-5,5-5" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                      </g>
                    </svg>
                </span>
              </div>
              <!-- crea sub menu de settings -->
              <div v-if="sidebar_global == 'Settings' || sidebar_global ==  '/Ensayos' || sidebar_global ==  '/ensayos'">
                <v-list class="my-0 px-2 content-setting">
                  <v-list-item
                  v-for="item in itemsSubmenu"
                  :key="item.title"
                  v-if = item.active
                  :class="activeClass(item.url)"
                  @click="sidebarsetting(item.key)"
                  >
                    <v-list-item-content
                      class="my-2 settings-hover menubar " :class=" ( btnsetting && (item.key === 'Samples') ) ? 'settings-hover1' : '' "
                      @click="getUrl(item)"
                    >
                      <v-list-item-title class="settings-hover d-flex align-item-center pl18 list-side c-sub-navbar-item fs12 text-left" :class="getlang(item.module)">
                        <svg
                        class="svg_settings" width="16" height="16">
                          <use :href='`${ item.icon }`'></use>
                        </svg>
                        <p class="mb-0 fs14 white-space">{{getlang(item.module)}}</p>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <!--
                  <v-list-item>
                    <v-list-item-content
                      class="my-2 settings-hover"
                      style="padding: 5px 0px !important;"
                    >
                      <v-list-item-title class="settings-hover d-flex align-item-center pl12 list-side c-sub-navbar-item justify-content-between o-language">

                        <svg class="svg_settings ml-1" width="16" height="16">
                          <use xlink:href="/img/icons/icons.svg#globe"></use>
                        </svg>
                        <v-select
                          class="select-idioma"
                          item-text="title"
                          item-value="locale"
                          :items="languages"
                          :placeholder="labelLang"
                          solo
                          dense
                          outlined
                          @input="changeLanguage($event)">
                        </v-select>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  -->
                </v-list>
              </div>
              <div v-else>
                <v-list class="my-0 px-2 content-setting">
                  <v-list-item
                  v-for="item in itemsSubots"
                  :key="item.title"
                  v-if="item.active"
                  :class="activeClass(item.url)"
                  @click="sidebarsetting(item.key)"
                  >
                    <v-list-item-content
                      class="my-2 settings-hover menubar " :class=" ( btnots && (item.key === 'CreateWorkOrder') ) ? 'settings-hover1' : '' "
                      @click="redireccion(item.url,item.isExterno)"
                    >
                      <v-list-item-title class="settings-hover d-flex align-item-center pl18 list-side c-sub-navbar-item fs12 text-left">
                        <svg
                        class="svg_settings" width="16" height="16">
                          <use :href='`${ item.icon }`'></use>
                        </svg>

                        <p class="mb-0 fs14 white-space">{{getlang(item.module)}}</p>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>

            </div>
            <!--fin submenu-->

            <!-- menu txa-lab  solo es el pcr-->
            <v-list  v-if="false">
              <v-list-item
                class="pl-1 h42 menu-hover"
                v-for="item in navitems"
                :key="item.title"
                :to="item.href"
                :disabled="item.activo"
                :style="item.module_active ? '': 'display:none'"
                link
                @click="resetbtnsetting(item.href)"

              >
                <div class="contents"
                  v-if="item.icon == 'listaDetalles' || item.icon == 'ensayos'"
                >
                  <v-list-item-content class="pl-4 menu-hover">
                    <div class="d-flex align-item-center">
                       <svg style="width: 42px !important; height: 22px !important;">
                          <use :xlink:href='`/img/icons/icons.svg#${ item.icon }`'></use>
                      </svg>
                      <span class="t-span">{{ item.title }}</span>
                    </div>
                  </v-list-item-content>
                </div>
                <div class="contents" v-else>
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </div>
              </v-list-item>
            </v-list>
            <!-- fin menu ppcr analisis -->

            <!-- menu de sesion -->
             <!-- <v-divider class="separador"></v-divider> -->

            <v-list >
              <v-list-item class="tooltip_menu  o-toggle-modal__li c-pointer relative">
                  <v-list-item-content class="tooltip_menu menu-hover py-2">
                    <a href="#" class='no-hover o-language' id="languageBtn">
                      <svg class="svg-icon">
                        <use xlink:href="/img/icons/icons.svg#globe"></use>
                      </svg>
                      <span class="tooltiptext_menu" >{{ $t('menu.language') }}</span>
                    </a>
                    <div class="d-lenguaje hidden">
                      <div class="v-input style-chooser v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder v-select">
                        <div class="v-input__control">
                          <v-select
                            class="select-idioma"
                            item-text="title"
                            item-value="locale"
                            :items="languages"
                            :placeholder="labelLang"
                            solo
                            dense
                            outlined
                            @input="changeLanguage($event)">
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </v-list-item-content>
              </v-list-item>

              <v-list-item
                @click.prevent="salir()"
                class="my-3"
              >
                  <v-list-item-content class="tooltip_menu menu-hover py-2">
                    <svg class="svg-icon">
                      <use xlink:href="/img/icons/icons.svg#cerrarSesion"></use>
                    </svg>
                    <span class="tooltiptext_menu">{{ $t('menu.salir') }}</span>
                  </v-list-item-content>
              </v-list-item>
            </v-list>
            <!-- fin menu session -->

        </v-navigation-drawer>
        <v-main  class="bg--light__gray">
          <div v-if="isRestricted">
            <Errorr/>
          </div>
          <!-- <div :class="{ 'padding-left-custom': customPadding }"></div> -->
          <div v-else>
            <v-container class="conta bg--light__gray " :class="(customPadding) ? 'padding-left-custom' : 'hola'" fluid>
            <router-view></router-view>
          </v-container>
          </div>
        </v-main>
      </v-app>
    </v-app>
    <v-overlay opacity="1" :value="overlay" style="z-index: 100 !important;">
      <div class="loader">
        <div id="dot1"></div>
        <div id="dot2"></div>
        <div id="dot3"></div>
        <div id="dot4"></div>
        <div id="dot5"></div>
        <div id="dot6"></div>
        <div id="dot7"></div>
        <div id="dot8"></div>
        <div id="dot9"></div>
        <div id="dot10"></div>
        <div id="base1"></div>
        <div id="base2"></div>
        <div id="base3"></div>
        <div id="base4"></div>
        <div id="base5"></div>
        <div class="loader-content">
            <p class="p-body">{{$t('menu.cargando')}}...</p>
        </div>
      </div>
    </v-overlay>
    <div v-if="(!token)">
      <Login/>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Login from '@/components/Login.vue';
import axios from 'axios';
import Service from './services/apis';
import i18n, { il8n } from './plugins/il8n';
import Errorr from './components/Error.vue';

export default {
  name: 'App',

  components: {
    Login,
    Errorr,
  },
  computed: {
    languages() {
      const lang = [
        { locale: 'es', title: 'Español', value: 'es' },
        { locale: 'en', title: 'English', value: 'en' },
        { locale: 'fr', title: 'Français', value: 'fr' },
        { locale: 'nl', title: 'Nederlands', value: 'nl' },
      ];
      return lang;
    },
    navmenu() {
      const newNavItems = [
        {
          title: 'Dashboard',
          icon: 'home',
          href: `${process.env.VUE_APP_TXA}home/dashboard`,
          active: false,
          module: 'Dashboard',
          module_active: true,
        },
        // {
        //   title: i18n.t('menu.crear_ot'),
        //   icon: 'crearot',
        //   href: `${process.env.VUE_APP_TXA}/ControlesDinamicos`,
        //   active: this.activeItems,
        //   module: 'Crear_OT',
        //   module_active: true,
        // },
        // {
        //   title: i18n.t('menu.pre_ot'),
        //   icon: 'preOt',
        //   href: `${process.env.VUE_APP_TXA}home/ListapreOTa`,
        //   active: this.activeItems,
        //   module: 'Pre_OT',
        //   module_active: true,
        // },
        // {
        //   title: i18n.t('menu.ot_muestra'),
        //   icon: 'otMuestras',
        //   href: `${process.env.VUE_APP_TXA}home/ListaOTN`,
        //   active: this.activeItems,
        //   module: 'OT_Muestras',
        //   module_active: true,
        // },
        // {
        //   title: i18n.t('menu.ot_com'),
        //   icon: 'otCompletas',
        //   href: `${process.env.VUE_APP_TXA}home/ListaOTA`,
        //   active: this.activeItems,
        //   module: 'OT_Completas',
        //   module_active: true,
        // },
        {
          title: 'TXA-QA',
          icon: 'datacenter',
          href: `${process.env.VUE_APP_TXA}home/ListaOTA`,
          active: this.activeItems,
          module: 'TXA_QA',
          module_active: true,
        },
        {
          title: i18n.t('menu.lote_medio'),
          icon: 'lote',
          href: `${process.env.VUE_APP_TXA}home/condfigLotes`,
          active: this.activeItems,
          module: 'Lote_Ensayo',
          module_active: true,
        },
      ];
      return newNavItems;
    },
    sidebarSoli() {
      const itemsSidebarSoli = [
        {
          title: 'Solicitudes ingresadas',
          icon: '/img/icons/icon-side.svg#test',
          href: '/solicitudes',
          txa: 0,
          name: 'ingresadas',
        },
        {
          title: 'Coordinacion de solicitudes',
          icon: '/img/icons/icon-side.svg#test',
          href: '/coordinacionsolicitudes',
          txa: 0,
          name: 'coordinacion',
        },
      ];
      return itemsSidebarSoli;
    },
    navitems() {
      const menu = [
        {
          title: i18n.t('menu.analisis'),
          icon: 'listaDetalles',
          href: '/analisis',
          activo: this.activeItems,
          module: 'Subida_Resultados_PCR',
          module_active: true,
        },
        // { title: i18n.t('menu.ensayo'), icon: 'ensayos', path: '/ensayos' },
      ];
      return menu;
    },
    menuBase_old(){
      return {
        "key": "TXA_LAB",
        "module": "TXA-LAB",
        "icon": "",
        "isMenu": 0,
        "isExterno": 0,
        "url": "",
        "isDisabled": false,
        "show": true,
        "active": true,
        "items": [
          {
            "key": "Dashboard",
            "module": "Dashboard",
            "icon": "/img/icons/icons.svg#home",
            "isMenu": 0,
            "isExterno": 1,
            "url": `${process.env.VUE_APP_TXA}home/dashboard`,
            "isDisabled": true,
            "show": true,
            "active": true
          },
          {
            "key": "CreateWorkOrder",
            "module": i18n.t('menu.crear_ot'),
            "icon": "/img/icons/icons.svg#crearot",
            "isMenu": 0,
            "isExterno": 1,
            "url": `${process.env.VUE_APP_TXA}/ControlesDinamicos`,
            "isDisabled": false,
            "show": true,
            "active": true
          },
          {
            "key": "PreWorkOrders",
            "module": i18n.t('menu.pre_ot'),
            "icon": "/img/icons/icons.svg#preOt",
            "isMenu": 0,
            "isExterno": 1,
            "url": `${process.env.VUE_APP_TXA}home/ListapreOTa`,
            "isDisabled": false,
            "show": true,
            "active": true
          },
          {
            "key": "WorkOrdersSamples",
            "module": i18n.t('menu.ot_muestra'),
            "icon": "/img/icons/icons.svg#otMuestras",
            "isMenu": 0,
            "isExterno": 1,
            "url": `${process.env.VUE_APP_TXA}home/ListaOTN`,
            "isDisabled": false,
            "show": true,
            "active": true
          },
          {
            "key": "WorkOrdersCompleted",
            "module": i18n.t('menu.ot_com'),
            "icon": "/img/icons/icons.svg#otCompletas",
            "isMenu": 0,
            "isExterno": 1,
            "url": `${process.env.VUE_APP_TXA}home/ListaOTA`,
            "isDisabled": false,
            "show": true,
            "active": true
          },
          {
            "key": "TXA_QA",
            "module": "TXA-QA",
            "icon": "/img/icons/icons.svg#datacenter",
            "isMenu": 0,
            "isExterno": 1,
            "url": `${process.env.VUE_APP_TXQA}`,
            "isDisabled": false,
            "show": true,
            "active": true
          },
          {
            "key": "ProductLot",
            "module": i18n.t('menu.lote_medio'),
            "icon": "/img/icons/icons.svg#lote",
            "isMenu": 0,
            "isExterno": 1,
            "url": `${process.env.VUE_APP_TXA}home/condfigLotes`,
            "isDisabled": false,
            "show": true,
            "active": true
          },
          {
            "key": "PCRResultsAnalysis",
            "module": i18n.t('menu.analisis'),
            "icon": "/img/icons/icons.svg#listaDetalles",
            "isMenu": 0,
            "isExterno": 0,
            "url": "analisis",
            "isDisabled": false,
            "show": true,
            "active": true
          },
          {
            "key": "EditWOResults",
            "module": "Edit WO Results",
            "icon": "",
            "isMenu": 0,
            "isExterno": 0,
            "url": "",
            "isDisabled": false,
            "show": false,
            "active": false
          },
          {
            "key": "Settings",
            "module": i18n.t('menu.setting'),
            "icon": "/img/icons/icons.svg#settings",
            "isMenu": 1,
            "isExterno": 0,
            "url": "",
            "isDisabled": false,
            "show": true,
            "active": true,
            "items": [
              {
                "key": "Samples",
                "module": "Samples",
                "icon": "/img/icons/icon-side.svg#sample",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}Campos`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
              {
                "key": "PlantsLocations",
                "module": "Plants and locations",
                "icon": "/img/icons/icon-side.svg#plants",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}Settings/PlantsLocations`,
                "isDisabled": false,
                "show": true,
                "active": true,
                "items": [
                  {
                    "key": "EditPlant",
                    "module": "Edit Plant",
                    "icon": "/img/icons/icons.svg#plants",
                    "isMenu": 0,
                    "isExterno": 0,
                    "url": "Settings/EditPlant",
                    "isDisabled": false,
                    "show": true,
                    "active": true
                  }
                ]
              },
              {
                "key": "LaboratoryTest",
                "module": "Laboratory test",
                "icon": "/img/icons/icon-side.svg#test",
                "isMenu": 0,
                "isExterno": 0,
                "url": "ensayos",
                "isDisabled": false,
                "show": true,
                "active": true
              },
              {
                "key": "StaffManager",
                "module": "Staff manager",
                "icon": "/img/icons/icon-side.svg#users",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}Settings/StaffManager`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
              {
                "key": "ResultsReport",
                "module": "Result report",
                "icon": "/img/icons/icon-side.svg#report",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}Settings/ResultsReport`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
              {
                "key": "Account",
                "module": "Account information",
                "icon": "/img/icons/icon-side.svg#info",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}Settings/Account`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
              // {
              //   "key": "Subscriptions",
              //   "module": "Subscriptions",
              //   "icon": "/img/icons/icon-side.svg#credit-card",
              //   "isMenu": 0,
              //   "isExterno": 1,
              //   "url": `${process.env.VUE_APP_TXA}Subscriptions`,
              //   "isDisabled": false,
              //   "show": true,
              //   "active": true
              // },
              {
                "key": "Laboratories",
                "module": "Laboratories",
                "icon": "/img/icons/icon-side.svg#lab",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}Settings/Laboratories`,
                "isDisabled": false,
                "show": true,
                "active": true
              }
            ]
          },

        ]
      }
    },
    menuBase_oldDos(){ // esto de seguro lo hizo Jose oaxaquin... 😒
      return {
        "key": "TXA_LAB",
        "module": "TXA-LAB",
        "icon": "",
        "isMenu": 0,
        "isExterno": 0,
        "url": "",
        "isDisabled": false,
        "show": true,
        "active": true,
        "items": [
          {
            "key": "Dashboard",
            "module": "Dashboard",
            "icon": "/img/icons/icons.svg#home",
            "isMenu": 0,
            "isExterno": 1,
            "url": `${process.env.VUE_APP_TXA}home/dashboard`,
            "isDisabled": true,
            "show": true,
            "active": true
          },
          {
            "key": "WorkOrdersyRequests",
            "module": i18n.t('menu.WorkOrdersyRequests'),
            "icon": "/img/icons/icons.svg#preOt",
            "isMenu": 1,
            "isExterno": 1,
            "url": "",
            "isDisabled": false,
            "show": true,
            "active": true,
            "items": [
            {
                "key": "CreateWorkOrder",
                "module": i18n.t('menu.crear_ot'),
                "icon": "/img/icons/icons.svg#crearot",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}/ControlesDinamicos`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
              {
                "key": "RequestsList",
                "module": i18n.t('menu.RequestsList'),
                "icon": "/img/icons/icons.svg#otMuestras",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}Requests/RequestsList`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
              {
                "key": "PreWorkOrders",
                "module": i18n.t('menu.pre_ot'),
                "icon": "/img/icons/icons.svg#otMuestras",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}home/ListapreOTa`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
              {
                "key": "WorkOrdersSamples",
                "module": i18n.t('menu.ot_muestra'),
                "icon": "/img/icons/icons.svg#otMuestras",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}home/ListaOTN`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
              {
                "key": "WorkOrdersCompleted",
                "module": i18n.t('menu.ot_com'),
                "icon": "/img/icons/icons.svg#otCompletas",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}home/ListaOTA`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
              {
                "key": "worksheet",
                "module": i18n.t('menu.worksheet'),
                "icon": "/img/icons/icons.svg#otCompletas",
                "isMenu": 0,
                "isExterno": 1,
                "url":  `${process.env.VUE_APP_TXQA}App/lista-bitacoras/WorkOrdersyRequests`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
              {
                "key": "worksheetMicro",
                "module": i18n.t('menu.worksheetMicro'),
                "icon": "/img/icons/icons.svg#otCompletas",
                "isMenu": 0,
                "isExterno": 1,
                "url":  `${process.env.VUE_APP_TXQA}App/worksheet-micro/WorkOrdersyRequests`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
            ]

            },
            {
            "key": "TXA_QA",
            "module": "TXA-QA",
            "icon": "/img/icons/icons.svg#datacenter",
            "isMenu": 0,
            "isExterno": 1,
            "url": `${process.env.VUE_APP_TXQA}`,
            "isDisabled": false,
            "show": true,
            "active": true
          },
          {
            "key": "ProductLot",
            "module": i18n.t('menu.lote_medio'),
            "icon": "/img/icons/icons.svg#lote",
            "isMenu": 0,
            "isExterno": 1,
            "url": `${process.env.VUE_APP_TXA}home/condfigLotes`,
            "isDisabled": false,
            "show": true,
            "active": true
          },
          {
            "key": "PCRResultsAnalysis",
            "module": i18n.t('menu.analisis'),
            "icon": "/img/icons/icons.svg#listaDetalles",
            "isMenu": 0,
            "isExterno": 0,
            "url": "analisis",
            "isDisabled": false,
            "show": true,
            "active": true
          },
          {
            "key": "EditWOResults",
            "module": "Edit WO Results",
            "icon": "",
            "isMenu": 0,
            "isExterno": 0,
            "url": "",
            "isDisabled": false,
            "show": false,
            "active": false
          },
          {
            "key": "Settings",
            "module": i18n.t('menu.setting'),
            "icon": "/img/icons/icons.svg#settings",
            "isMenu": 1,
            "isExterno": 0,
            "url": "",
            "isDisabled": false,
            "show": true,
            "active": true,
            "items": [
              {
                "key": "Samples",
                "module": "Samples",
                "icon": "/img/icons/icon-side.svg#sample",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}Campos`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
              {
                "key": "PlantsLocations",
                "module": "Plants and locations",
                "icon": "/img/icons/icon-side.svg#plants",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}Settings/PlantsLocations`,
                "isDisabled": false,
                "show": true,
                "active": true,
                "items": [
                  {
                    "key": "EditPlant",
                    "module": "Edit Plant",
                    "icon": "/img/icons/icons.svg#plants",
                    "isMenu": 0,
                    "isExterno": 0,
                    "url": "Settings/EditPlant",
                    "isDisabled": false,
                    "show": true,
                    "active": true
                  }
                ]
              },
              {
                "key": "SamplingProgram",
                "module": "Sampling program",
                "icon": "/img/icons/icon-side.svg#samplingprogram",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}Settings/SamplingProgram`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
              {
                "key": "LaboratoryTest",
                "module": "Laboratory test",
                "icon": "/img/icons/icon-side.svg#test",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXQA}App/ensayos-new/Settings`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
              {
                "key": "StaffManager",
                "module": "Staff manager",
                "icon": "/img/icons/icon-side.svg#users",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}Settings/StaffManager`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
              {
                "key": "ResultsReport",
                "module": "Result report",
                "icon": "/img/icons/icon-side.svg#report",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}Settings/ResultsReport`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
              {
                "key": "Account",
                "module": "Account information",
                "icon": "/img/icons/icon-side.svg#info",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}Settings/Account`,
                "isDisabled": false,
                "show": true,
                "active": true
              },
              // {
              //   "key": "Subscriptions",
              //   "module": "Subscriptions",
              //   "icon": "/img/icons/icon-side.svg#credit-card",
              //   "isMenu": 0,
              //   "isExterno": 1,
              //   "url": `${process.env.VUE_APP_TXA}Subscriptions`,
              //   "isDisabled": false,
              //   "show": true,
              //   "active": true
              // },
              {
                "key": "Laboratories",
                "module": "Laboratories",
                "icon": "/img/icons/icon-side.svg#lab",
                "isMenu": 0,
                "isExterno": 1,
                "url": `${process.env.VUE_APP_TXA}Settings/Laboratories`,
                "isDisabled": false,
                "show": true,
                "active": true
              }
            ]
          },
        ]
      }
    },
    menuBase: {
      get()  { return this.menuBaseLts; },
      set(value) { this.menuBaseLts = value; },
    },
  },
  data: () => ({
    claseactiva: '',
    sidebar_global: '',
    hrefanalisis: '',
    anchoCaja: '-203px',
    showanchocaja: false,
    showanchocaja_ot: false,
    showanchocaja2: false,
    customPadding: false,
    btnsoli: false,
    btnsoli2: false,
    menu: false,
    activeItems: true,
    btnPress: false,
    btnPress_ots: false,
    btnPress2: true,
    btnsetting: true,
    btnots: true,
    show1: true,
    show2: false,
    menuBaseLts: {},
    labelLang: i18n.t('English'), // $t('english')
    langs: [
      { title: 'Español', locale: 'es', value: 'es' },
      { title: 'Inglés', locale: 'en', value: 'en' },
    ],
    selectedLang: null,
    token: true,
    overlay: true,
    drawers: ['Default (no property)', 'Permanent', 'Temporary'],
    show: true,
    urlTXA: 'http://txa-lab-des.taag.mx/',
    primaryDrawer: {
      model: null,
      type: 'default (no property)',
      clipped: false,
      floating: false,
      mini: false,
    },
    footer: {
      inset: false,
    },
    isRestricted: false,

    nombre_cuadro: '',
    email_cuadro: '',

    itemsSubmenu: [],
    itemsSubots: [],
    showsubmenu: false,
    showsubmenu2: false,
    lastClick: null,
    globalKey: '',
  }),
  created() {
    console.log("state", this.$store.state.other)
    this.resetbtnsetting(window.location.pathname);
    this.interceptor();
    document.title = 'TXA Lab';
    this.$store.commit('setApp', this);
    const that = this;

    console.log('console that', that.$router.currentRoute);

    if (that.$router.currentRoute.name === 'logout') {
      localStorage.setItem('token', null);
      this.token = null;
      window.location.replace(`${process.env.VUE_APP_TXA}sylablogin/login`);
      return;
      // window.location.replace(`${process.env.VUE_APP_TXA}sylablogin/login`);
      // return;
    }
    // let modulesPermissions1 = null;
    setTimeout(async () => {
      // CUANDO SE LOGEA POR API
      // console.log('1')
      const routeName = that.$router.currentRoute.name;
      if (that.$router.currentRoute.params.lang) {
        switch (that.$router.currentRoute.params.lang) {
          case 'spanish':
            il8n.locale = 'es';
            break;
          case 'english':
            il8n.locale = 'en';
            break;
          default:
            il8n.locale = 'en';
            break;
        }
        localStorage.setItem('lang', il8n.locale);
      }
      // else {
      //   il8n.locale = 'en';
      // }

      if(localStorage.getItem('lang') === null) {
        localStorage.setItem('lang', 'en');
        il8n.locale = localStorage.getItem('lang');
      } else {
        il8n.locale = localStorage.getItem('lang');
      }

      if ((that.$router.currentRoute.name === 'CoordinacionSolicitudes' || that.$router.currentRoute.name === 'loginApi' || that.$router.currentRoute.name === 'loginensayo') && that.$router.currentRoute.params.apiToken) {
        const validate = await that.verifyToken(that.$router.currentRoute.params.apiToken);
        if (validate) {
          localStorage.setItem('token', that.$router.currentRoute.params.apiToken);
        }
      }

      this.token = localStorage.getItem('token');
      this.token = (!this.token || this.token === 'null') ? null : this.token;
      if (this.token) {
          //  console.log('2')
        const validate = await that.verifyToken(this.token);
        if (!validate) {
          // console.log('3')
          localStorage.setItem('token', null);
          this.token = null;
          window.location.replace(`${process.env.VUE_APP_TXA}sylablogin/login`);
          return;
        }
        // hacemos la peticion para los permisos
        const permisos = await Service.apiToken('POST', 'get-permiso', this.$ls.storage.token);
        await this.filtrarPermiso(JSON.parse(permisos.modulos.modulos));
        // se arma el json de menu lateral
        const jsonmenu = JSON.parse(permisos.response.json);
        const menusteriplex = [];
        if (jsonmenu.slablts) {
            //  console.log('4')
          const permisosteriplex = Object.values(jsonmenu.slablts);
          permisosteriplex.forEach((element) => {
            if (element.activo === 1) {
              menusteriplex.push(element);
            }
          });
          // this.navitems = menusteriplex;
        }

        // activamos el menu si tenemos activo un ensayo
        if (permisos.data) {
          this.activeItems = false;
        }

        const modulesPermissions = JSON.parse(permisos.modulos.modulos);
        // modulesPermissions1 = modulesPermissions;
        await this.getDataMenu();
        console.warn('actualizando menu', this.menuBase)
        console.log({modulesPermissions})
        if (modulesPermissions) {
          this.navmenu.forEach((menu) => {
            /* eslint-disable no-param-reassign */
            // console.log({menu:menu});
            menu.module_active = true;// (modulesPermissions[menu.module]) ? ((modulesPermissions[menu.module].activo) ? true : false ) : false ;
            // (menu.module_active) ? '1' : '0';
          });
          this.navitems.forEach((menu) => {
            /* eslint-disable no-param-reassign */
             menu.module_active = true;// (modulesPermissions[menu.module]) ? ((modulesPermissions[menu.module].activo) ? true : false ) : false ;
            // menu.module_active = (modulesPermissions[menu.module].activo === '1');
            this.isRestricted = false; !menu.module_active;
          });
          // se termina de armar el menu
        }

    // console.log('modulesPermissions1', modulesPermissions[0].items[5].items)
    /*
      Efren: 03-feb-2023 12:00 use el arreglo de modulesPermissions que viene del Back con los permisos con el active de cada opcion
          Después hice un map en cada opcion de los submenues.
          Primero con el submenu de settints y despues con el de WorkOrdersyRequests.
          No obstante use el indice para acceder a las posiciones, lo cual no es la mejor manera.

      Efren: 03-feb-2023 12:55 Cambie la asignacion por 3 filter que sacan las posiciones para los 2 submenues y en el TXA_LAB.
          El problema es que no se con que variable se usa el arreglo de TXA_LAB o el de TXA_QA
    */
    console.log({modulesPermissions})
    let TXA_LAB = modulesPermissions.find(element => element.key == "TXA_LAB");
    console.log({TXA_LAB})
    let workordersyrequests = TXA_LAB.items.find(element => element.key == "WorkOrdersyRequests");
    let settings = TXA_LAB.items.find(element => element.key == "Settings");

    // SUBMENU Settings
    let items_sub_menu = this.menuBase.items.find(element => element.key == "Settings");
      // console.log('items_sub_menu', items_sub_menu.items)
        if(!!settings.items){
          items_sub_menu.items.map( (item, index) => {
            // return item.active = modulesPermissions[0].items[5].items[index].active;
            if( item.active ){
              return item.active = true;//settings.items[index].hasOwnProperty( "active" ) ? settings.items[index].active : item.active;
            }
          } );
        }
    // console.log('submenu1', items_sub_menu)
    this.itemsSubmenu = items_sub_menu.items;

    // SUBMENU WorkOrdersyRequests
    let items_sub_ots = this.menuBase.items.find(element => element.key == "WorkOrdersyRequests");
        // console.log('items_sub_ots', items_sub_ots.items)
        if(!!workordersyrequests.items){
          items_sub_ots.items.map( (item, index) => {
            // return item.active = modulesPermissions[0].items[1].items[index].active;
            if( item.active ){
              // return item.active = workordersyrequests.items[index].active ? workordersyrequests.items[index].active : item.active;
              return item.active = true;// workordersyrequests.items[index].hasOwnProperty( "active" ) ? workordersyrequests.items[index].active : item.active;
            }
          } );
        }
      // console.log('submenu2', items_sub_ots)
      this.itemsSubots = items_sub_ots.items;

        // comentar windows para trabajar local
          // console.log('5')
        this.init(routeName);

      } else if (window.location.pathname !== '/') {
        // window.location.replace(`${process.env.VUE_APP_TXA}sylablogin/login`);
        this.$router.push('/');
      } else if (that.$router.currentRoute.name === null) {
        // window.location.replace(`${process.env.VUE_APP_TXA}sylablogin/login`);
        this.$router.push('/');
      } else {
        // window.location.replace(`${process.env.VUE_APP_TXA}sylablogin/login`);
        this.$router.push('/');
      }
      this.$ls.on('token', (val) => {
        that.token = val;
      });
    }, 500);
    this.infoUsers();
    // console.log('submenu', this.menuBase.items);
/*
    let items_sub_menu = this.menuBase.items.find(element => element.key == "Settings");
    // console.log('submenu1', items_sub_menu)
    this.itemsSubmenu = items_sub_menu.items;

    let items_sub_ots = this.menuBase.items.find(element => element.key == "WorkOrdersyRequests");

      items_sub_ots.items.map( (item, index) => {
        console.log("items_sub_ots item",item)
        console.log("items_sub_ots index",index)
        console.log("items_sub_ots this.modulesPermissions",modulesPermissions1)
        return item.active = modulesPermissions1["TXA_LAB"].items["WorkOrdersyRequests"].items[index].active;
      } );

      // console.log('submenu2', items_sub_ots)
      this.itemsSubots = items_sub_ots.items;
 */
    // setTimeout( () => {
    // }, 1500);

  },
  watch: {
    token(val) {
      this.$ls.storage.token = val;
    },
    $route (to, from){
      this.resetbtnsetting(to.fullPath);
      // console.log('to',to);
      // console.log('from',from);
    }

  },
  methods: {
    activepcr(urlpcr){
      // console.log('entraactivepcr');
      this.hrefanalisis = urlpcr.toLowerCase();
      // console.log('this.hrefanalisis',this.hrefanalisis);

    },

    checkItemURL(item){
      // console.log("checkItemURL", item)
      const pathname = (window.location.pathname).replace(/\//g, "").toLowerCase();
      const url = item.url.toLowerCase();
      if( !!url && url == pathname ) {
        // console.log("checkItemURL", pathname, url )
        return  true
      };
    },

    getlang(texto){
      if (texto == null){
        return texto = '--';
      }
      let text_tranform = texto.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "").replace(/,/g, "").replace(/-/g, "").replace(/&/g, "");
        console.log(text_tranform);
      let txt_traduccion = i18n.t('submenu.'+[`${text_tranform}`]);

      if(!txt_traduccion.includes('submenu.')){
        console.log(txt_traduccion);
        // return i18n.t('analisis.superficies');
        return txt_traduccion;
      }else{
        // return i18n.t('analisis.superficies');
        return texto;
      }

    },
    async infoUsers() {
      const info = {
        datoenv: 1,
      };
      const datosUser = await Service.apiToken('POST', 'get-userinfo', this.$ls.storage.token, info);
      this.nombre_cuadro = `${datosUser.data.user.name} ${datosUser.data.user.apellido}`;
      this.email_cuadro = datosUser.data.user.email;
    },
    async getDataMenu() {
      const info = {
        datoenv: 1,
      };
      const dataMenus = await Service.apiToken('POST', 'get-data-menus', this.$ls.storage.token, info);
      console.log(dataMenus.data.menu);
      if (dataMenus.data.status === 'success') {
        console.warn('actualizando menus');
        this.menuBase = dataMenus.data.menu;
      }
    },
    redireccion(href, txa) {
      if (txa === 0) {
        this.$router.push(href);
      } else {
        window.open(href, '_parent');
      }
    },
    mantenedorCampos() {
      const linkslab = `${process.env.VUE_APP_TXA}Campos`;
      window.open(linkslab, '_blank');
    },
    sidebarsetting(keymodule) {
      // console.log('sidebarsetting',keymodule);
      if(keymodule === 'Samples'){
        this.btnsetting = (keymodule === 'Samples');
      }else{
        this.btnots= (keymodule === 'CreateWorkOrder');
      }
    },
    sidebar_close() {
      this.showanchocaja = false;
      this.showanchocaja_ot = false;
      this.customPadding = false;
      this.btnPress = false;
      this.btnPress_ots = false;
      this.btnPresssoli = false;
      this.show1 = false;
      this.show2 = false;
      this.btnsetting = false;
      this.btnots = false;
      this.showsubmenu = false; /// oculta submenu
      this.anchoCaja = "-203px";
      this.claseactiva = '';
    },
    resetbtnsetting(href) {
      // console.log('resetbtnsetting',href)
      if (href === '/analisis') {
        this.btnPress = false;
        this.btnPress_ots = false;
        this.show1 = false;
        this.btnsetting = false;
        this.btnots = false;
        this.customPadding = false;
      }
      // console.log('pathnamehref',href)
      if ( href === '/ensayos' || (window.location.pathname).toLowerCase() === '/ensayos') {
          // this.activeClass(window.location.pathname);
          this.showSubmenu({key: href});
      }
      else{
        // console.log('pathnameelse',window.location.pathname)
        this.sidebar_close();
        this.activepcr(window.location.pathname);
        // this.hrefanalisis = window.location.pathname;
        //  console.log('hrefanalisis',hrefanalisis)
      }
    },
    changeLanguage(locale) {
      i18n.locale = locale;
      localStorage.setItem('locale', i18n.locale);
      console.log("locale",i18n.locale)
    },
    interceptor() {
      axios.interceptors.response.use((response) => response, (error) => {
        if (error.response.status === 401) {
        // place your reentry code
          this.localStorage('token', null);
          this.token = null;
        }
        return error;
      });
    },
    init(route = null) {
      this.getlang();
      this.token = this.$ls.storage.token;
      // console.log('token', this.token);
      // SE OBTIENE LA BARRA LATERAL E INFORMACIÓN INICIAL
      if (route === null) {
        this.$router.push(this.navitems[0].path);
      } else if (route === 'loginApi') {
        this.$router.push('/interpretacion');
      } else if (route === 'loginensayo') {
        // console.log('loginensayoentra')
        this.$router.push('/interpretacion');
        this.resetbtnsetting('/interpretacion');
      } else if (route === 'CoordinacionSolicitudes') {
        this.$router.push('/coordinacionsolicitudes').catch(()=>{}); // se añadio este catch x un error visual
      }else {
        this.$router.push( `/${route}`, () => {} ); // Efren (2022-07-07): Aqui da un error con el mensaje. NavigationDuplicated: Avoided redundant navigation to current location: "/Ensayos" Si se comenta esta linea ya no sale el error.
        // Efren (2022-07-25): puse una función de flecha para resolver el problema de NavigationDuplicated. Tomando en cuenta a solución de este link https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
        // this.$router.push('/ensayos');
      }

      setTimeout(() => {
        this.overlay = false;
      }, 500);
    },
    salir() {
      localStorage.removeItem('token');
      this.token = null;
      window.location.replace(`${process.env.VUE_APP_TXA}sylablogin/logout_lts`);
      //this.$router.push('/');
    },
    setOverlay(value) {
      this.overlay = value;
    },
    async verifyToken(apiToken) {
      let response = false;
      await axios.post(`${process.env.VUE_APP_URL_API}login/verifyToken`, { token: apiToken }).then(() => {
        response = true;
      }, (error) => {
        console.warn('error de login por api', error);
      });
      // this.$refs.loginComponent.loading = false;
      return response;
    },
    activeClass( url ){
      // console.log('siderglobal',this.sidebar_global);
      let url2 = '/'+url.toLowerCase();
      // console.log('url2',url2);
      if( url2 == (window.location.pathname).toLowerCase() ){
        return 'active';
      }
    },
    // ver submenu
    showSubmenu(item){
      switch (item.key) {
        case '/Ensayos': case '/ensayos':
          this.showmenu_ensayo();
          this.sidebar_global = item.key;
          //  return item = item.key;
          break;
        case 'Settings':
          this.showmenu_settings(item);
          this.sidebar_global = item.key;
          // return item = item.key;
          break;
        case 'WorkOrdersyRequests':
          this.showmenu_settings(item);
          this.sidebar_global = item.key;
          // return item = item.key;
          break;
        default:
          this.showsubmenu = false;
          this.customPadding = false;
          this.showanchocaja = false;
      }

      if(!item){
        // this.anchoCaja = "0px";
        this.showsubmenu = false;
        this.customPadding = false;
        this.showanchocaja = false;
        return;
      };
      if (item.isExterno === 0) {
        this.$router.push(item.url, () => {} ); // Efren (2022-07-07): Aqui da un error con el mensaje. NavigationDuplicated: Avoided redundant navigation to current location: "/Ensayos" Si se comenta esta linea ya no sale el error.
        // Efren (2022-07-25): puse una función de flecha para resolver el problema de NavigationDuplicated. Tomando en cuenta a solución de este link https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
        // this.$router.push('/ensayos');
      } else {
        window.open(item.url, '_parent');
      }

      // this.itemsSubmenu = item.items;
      // console.log(this.itemsSubmenu)
    },
    showmenu_ensayo(){
      this.showsubmenu = true;
      this.customPadding = true;
      this.showanchocaja = true;
      this.lastClick = 'Settings';
    },
    showmenu_settings(item){
      if(this.lastClick == item.key){
        console.log('entra caso 2');
        this.showanchocaja = !this.showanchocaja;
        this.showsubmenu =  !this.showsubmenu;
        this.customPadding = !this.customPadding;
        // this.lastClick = item.key;
      }else{
        console.log('entra caso 3');
        this.showanchocaja = true;
        this.showsubmenu = true;
        this.customPadding = true;
        this.lastClick = item.key;
      }
    },
    getUrl(item){
      let url = "/"+item.url;
      // console.log("item", url)
      let current_url = this.$route.path.toLowerCase();
      // console.log("current url ", current_url)
      if( url !== current_url ){
        this.redireccion(item.url,item.isExterno);
      }
      else{
        if( url = "/ensayos"){
          // location.reload()
          // this.$router.push(url)
          this.$store.state.seeTable = true;
          // this.setSee(false, null, true);
        }
      }
    },
    filtrarPermiso(jsonpermiso){

      let menulateralBD = (jsonpermiso) ? jsonpermiso[0] : [];
      if(menulateralBD.length > 0){
        let permisoNew = [];
        this.menuBase.items.forEach(element => {
          let activo = false;
            menulateralBD.items.forEach(elementbd => {
              if(element.isMenu === 0){
                if (element.key == elementbd.key ) {
                  activo = elementbd.active
                }
              }
              else{
                if(element.key == elementbd.key){
                  let menuBD = elementbd.items;
                  let menusub = 0;
                  let permisoNewsub = [];
                  element.items.forEach(elementmenu => {
                    let activomenu = false;
                    menuBD.forEach(elementbdmenu =>{
                      if (elementmenu.key == elementbdmenu.key ) {
                        activomenu = elementbdmenu.active;
                        menusub ++;
                      }
                    })
                    elementmenu.active = activomenu;
                    permisoNewsub.push(elementmenu);
                  })
                  element.items  = permisoNewsub;
                  activo= (menusub==0) ? false : true;
                }
              }
            });
            element.active = activo;
            permisoNew.push(element);
        });
        this.menuBase.items = permisoNew;
        // console.log({thismenuBaseitems:this.menuBase.items})
      }

    }
  },
  async mounted() {
      // console.log("navmenu",this.navmenu)
      // console.log("itemsSubmenu",this.itemsSubmenu)
      // console.log("itemsSubots",this.itemsSubots)
      // console.log("modulesPermissions",this.modulesPermissions)
      languageBtn.addEventListener("click", function (ev){
        ev.preventDefault();
        let el = ev.currentTarget;
        let parent = this.parentNode;
        let d_lenguaje = parent.querySelector(".d-lenguaje");
        d_lenguaje.classList.toggle("hidden")
      }, false);

      app.addEventListener("click", function (ev){
        let el = ev.target;
        if( !el.closest(".o-language, .d-lenguaje") ){
          let d_lenguaje = app.querySelector(".d-lenguaje");
          d_lenguaje.classList.add("hidden")
        }
      });

  },
};
</script>
