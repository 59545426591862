import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
const routes = [
  {
    path: '/logout',
    name: 'logout',
    component: null,
    meta: { transition: 'zoom' },
  },
  {
    path: '/loginApi/:apiToken/:lang',
    name: 'loginApi',
    component: null,
    meta: { transition: 'zoom' },
  },
  /* {
    path: '/loginensayo/:apiToken/:lang',
    name: 'loginensayo',
    component: null,
    meta: { transition: 'zoom' },
  }, */
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/components/Login.vue'),
    meta: { transition: 'zoom' },
  },
  /* {
    path: '/ensayos/:lang',
    name: 'Ensayos',
    component: () => import('../components/Ensayos.vue'),
    meta: { transition: 'zoom' },
  }, */
  {
    path: '/analisis',
    name: 'Analisis',
    redirect: { name: 'interpretacion' },
    component: () => import('../components/Analisis.vue'),
    meta: { transition: 'zoom' },
  },
  {
    path: '/solicitudes',
    name: 'Solicitudes',
    component: () => import('../components/Solicitudes/solicitudIngresada.vue'),
    meta: { transition: 'zoom' },
  },
  {
    path: '/coordinacionsolicitudes',
    name: 'CoordinacionSolicitudes',
    component: () => import('../components/Solicitudes/coordinacion.vue'),
    meta: { transition: 'zoom' },
  },
  {
    path: '/CoordinacionSolicitudes/:apiToken/:lang',
    name: 'CoordinacionSolicitudes',
    component: null,
    meta: { transition: 'zoom' },
  },
  {
    path: '/mantenedor',
    name: 'Mantenedor',
    component: () => import('../components/Mantenedor/Notas.vue'),
    meta: { transition: 'zoom' },
  },
  {
    path: '/addEnsayos',
    name: 'AddEnsayos',
    component: () => import('../components/nuevoEnsayo.vue'),
    meta: { transition: 'zoom' },
  },
  {
    path: '/uploadFolder',
    name: 'UploadFolder',
    component: () => import('../components/UploadFolder.vue'),
    meta: { transition: 'zoom' },
  },
  {
    path: '*',
    component: () => import('../components/Ensayos.vue'),
    meta: { transition: 'zoom' },
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../components/test.vue'),
    meta: { transition: 'zoom' },
  },
  {
    path: '/interpretacion',
    name: 'interpretacion',
    component: () => import('../components/Interpretacion/Interpretacion.vue'),
    meta: { transition: 'zoom' },
  },
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
export default router;
