import Vue from 'vue';
import Storage from 'vue-ls';
import Axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/il8n';

const options = {
  namespace: 'vuejs__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
};

Vue.use(Storage, options);
Vue.prototype.$http = Axios;
Vue.config.productionTip = false;

const instance = Axios.create();
instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
)

instance.defaults.validateStatus = function (status) {
  return status >= 200 && status < 300;
}
instance.defaults.timeout = 0;
window.axios = instance;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render(h) { return h(App); },
}).$mount('#app');
