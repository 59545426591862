const fr = {
  'Tradicional Microbiology': 'Microbiologie classique',
  'Molecular Testing': 'Tests moléculaires',
  'Chemical Testing': 'Tests chimiques',
  Other: 'Autre',
  'Raw material': 'Matière première',
  'Finished product': 'Produit fini',
  'Semi elaborated': 'Semi-élaboré',
  'Fish and fishery products': 'Poisson et produits de la pêche',
  'Materia prima': 'Matière première',
  'Producto terminado': 'Produit fini',
  'Semi elaborado': 'Semi-élaboré',
  'Pescado y productos pesqueros': 'Poisson et produits de la pêche',

  'zone 1': 'Zone 1',
  'zone 2': 'Zone 2',
  'zone 3': 'Zone 3',
  'zona 1': 'Zone 1',
  'zona 2': 'Zone 2',
  'zona 3': 'Zone 3',

  Critical: 'Critique',
  'Not critical': 'Non critique',
  Crítica: 'Critique',
  'No crítica': 'Non critique',

  'Pre-sanitization': 'Prédésinfection',
  'Post-sanitization': 'Post-désinfection',
  'In use': 'En cours d’utilisation',
  'With gloves': 'Mains gantées',
  'Pre-sanitización': 'Prédésinfection',
  'Post-sanitización': 'Post-désinfection',
  'En uso': 'En cours d’utilisation',
  'Con guantes': 'Mains gantées',

  'Agua de Tratamiento': 'Eau de traitement',

  meses: {
    enero: 'Janvier',
    febrero: 'Février',
    marzo: 'Mars',
    abril: 'Avril',
    mayo: 'Mai',
    junio: 'Juin',
    julio: 'Juillet',
    agosto: 'Août',
    septiembre: 'Septembre',
    octubre: 'Octobre',
    noviembre: 'Octobre',
    diciembre: 'Décembre',
  },
  submenu: {
    btn_confirm: 'confirmer',
    enter_pass: 'Veuillez entrer votre mot de passe pour continuer',
    Workordersandrequests: 'Ordres de travail et demandes',
    createneworder: 'Créer un nouvel ordre',
    requestslist: 'Liste des demandes',
    preworkorderlist: 'Liste des pré-ordres de travail',
    workorderlist: 'Liste des ordres de travail',
    completeworkorders: 'Ordres de travail terminés',
    samples: 'Échantillons',
    plantsandlocations: 'Installations et emplacements',
    laboratorytest: 'Tests en laboratoire',
    staffmanager: 'Responsable du personnel',
    resultreport: 'Rapport des résultats',
    accountinformation: 'Informations relatives au compte',
    subscriptions: 'Abonnements',
    laboratories: 'Laboratoires',
    worksheet: 'Habitacle',
  },

  menu: {
    worksheet: 'Habitacle',
    cargando: 'En cours de chargement',
    WorkOrdersyRequests: 'Ordres de travail et demandes',
    RequestsList: 'Liste des demandes',
    Dismiss: 'Fermer',
    crear_ot: 'Créer un nouvel ordre',
    pre_ot: 'Liste des pré-ordres de travail',
    ot_muestra: 'Liste des ordres de travail',
    ot_com: 'Ordres de travail terminés',
    lote_medio: 'Lot de réactifs',
    analisis: 'Analyse des résultats PCR',
    ensayo: 'Tests de laboratoire',
    setting: 'Paramètres',
    language: 'Langue',
    salir: 'Déconnexion',
    aprende: 'Centre d’apprentissage',
    cerrar: 'Fermer',
    samples: 'Échantillons',
    Plants_locations: 'Installations et emplacements',
    Laboratory_test: 'Test de laboratoire',
    Users: 'Gestionnaire des utilisateurs',
    Analysts_supervisors: 'Analystes et superviseurs',
    Result_report: 'Rapport des résultats',
    Account_information: 'Informations relatives au compte',
    staff: 'Responsable du personnel',
    youplan: 'Votre plan',
    labs: 'Laboratoires',
    soli: 'Demandes',
    soli_ingresada: 'Demandes saisies',
    soli_coo: 'Coordination des demandes',
  },

  analisis: {
    no_equipo_compatible: 'Ce blog n`est pas compatible avec le matériel sélectionné',

    are_you_sure_overwrite_sample: 'Cet échantillon est déjà publié. Voulez-vous l`écraser ?',

    medio: 'Echantillon',
    no_medio_and_equipo: 'Es necesario seleccionar un equipo y un ensayo para continuar',
    medioSelect: 'Sélectionner le support',
    no_datos_bitacora: 'Il ny a pas de journal avec ces données',
    abandonar: 'Souhaitez-vous quitter le site ?',
    interno: 'Interne',
    Reviewyouranalysisresultsfor: 'Examinez vos résultats d’analyse pour',
    medioanalisis: 'Analyse',
    selectanalisis: 'Sélectionner une analyse',
    clase: 'Classe',
    Clase: 'Classe',
    well: 'Puits',
    Other: 'Autre',
    DETERIORADO: 'DÉTÉRIORATION',
    spoilage: 'DÉTÉRIORATION',
    NODETERIORADO: 'ABSENCE DE DÉTÉRIORATION',
    nonspoilage: 'ABSENCE DE DÉTÉRIORATION',
    deteriorado: 'DÉTÉRIORATION',
    nodeteriorado: 'ABSENCE DE DÉTÉRIORATION',
    positivo: 'Positif',
    negativo: 'Négatif',
    inhibido: 'Inhibé',
    vacio: 'Vide',
    zonagris: 'Zone grise',
    microorganismonoregistrado: 'Micro-organisme non enregistré',
    alimentosdeusoinfantil: 'Nourriture pour bébé',
    carnesyproductoscarneos: 'Viandes et produits carnés',
    pescadosyproductosdelapesca: 'Poisson et produits de la pêche',
    huevosyovoproductos: 'Œufs et ovoproduits',
    salsasaderezosespeciasycondimentos: 'Sauces, vinaigrettes, épices et condiments',
    comidasyplatospreparados: 'Plats préparés',
    productosdepanaderiaypasteleria: 'Produits de boulangerie et de pâtisserie',
    frutasyverduras: 'Fruits et légumes',
    bebidasyaguas: 'Boissons et eau',
    estimulantesyfruitivos: 'Stimulant et fruité',
    conservas: 'Confitures',
    noaplica: 'Ne s’applique pas',
    lechesyproductoslacteos: 'Lait et produits laitiers',
    heladosymezclasparahelados: 'Crème glacée et mélanges de crème glacée',
    productosgrasos: 'Produits gras',
    caldossopascremasymezclasdeshidratadas: 'Bouillons, soupes, crèmes et mélanges déshydratés',
    productoselaboradosapartirdecereales: 'Produits à base de céréales',
    azucaresymiel: 'Miel et sucres',
    productosdeconfiteria: 'Produits de confiserie',
    agua: 'Eau',
    alimentos: 'Aliments',
    ambientes: 'Environnements',
    manipuladores: 'Manipulateurs',
    superficies: 'Surfaces',
    controlpositivo: 'Contrôle positif',
    controlnegativo: 'Contrôle négatif',
    water: 'Eau',
    foods: 'Aliments',
    environments: 'Environnements',
    handlers: 'Manipulateurs',
    surfaces: 'Surfaces',
    positivecontrol: 'Contrôle positif',
    negativecontrol: 'Contrôle négatif',
    cePositivo: 'Contrôle positif de l`extraction',
    ceNegativo: 'Contrôle d`extraction négatif',
    cMedio: 'Contrôle moyen positif',
    cnMedio: 'Contrôle moyen négatif',
    noResults: 'Il n´y a pas de résultats pour ces paramètres',
    repetirReaccion: 'Erreur de profil, répéter la réaction',
    reaccionGris: 'Zone grise, réaction répétée',

    toolp: 'Sélectionnez autant de puits que vous le souhaitez, puis attribuez-leur automatiquement certains paramètres tels qu’une matrice, une catégorie d’aliments et un taux d’échantillon.',
    analizar: 'Analyser',
    txt_1: 'Analyser vos résultats PCR',
    txt_2: 'Télécversez votre fichier brut PCR pour une interprétation automatique des résultats',
    txt_3: 'Configurez votre test de laboratoire',
    txt_4: 'Configurez vos échantillons',
    txt_5: 'Saisir les échantillons manuellement',
    txt_6: 'Analyser un ordre de travail',
    matriz: 'Matrice',
    sub_matriz: 'Sous-matrice',
    sube: 'Téléversez votre fichier PCR et nous analyserons vos résultats !',
    error: 'Erreur de lecture de la structure du fichier sélectionné',
    mn: 'Échantillon introuvable',
    m: 'Échantillon trouvé',
    formato_invalido: 'Format de fichier non valide. Veuillez saisir un fichier avec l’extension',
    no_datos: 'Aucune donnée trouvée avec l’OT',
    no_muestra: 'Il n’y a aucun échantillon avec un kit Steriplex dans l’OT',
    bienvenido: 'Bienvenue',
    analiza: 'Analysez vos résultats de',
    selecciona_equipo: 'Sélectionnez votre test PCR',
    carga: 'Téléversez votre test PCR',
    Selecciona: 'Sélectionnez votre équipement PCR',
    ingresa: 'Saisir le numéro d’ordre de travail',
    categoria: 'Catégories',
    revisar_ot: 'Examiner',
    analizar_resultados: 'Analyser les résultats',
    forma_manual: 'Saisissez vos échantillons manuellement',
    acciones: 'Actes',
    aplicar_matriz: 'Appliquer la matrice',
    estado: 'État',
    codigo_muestra: 'Code d’échantillon',
    estado_muestra: 'État d’échantillon',
    ingresa_codigo: 'Saisir le code',
    seleccionar_matriz: 'Sélectionner une matrice',
    seleccionar_kit: 'Sélectionner une catégorie',
    seleccionar_status: 'Sélectionner un état',
    Resultados_analisis: 'Résultats de votre analyse',
    muestras_publicadas: 'Les échantillons ont été',
    muestras_publicadas_txt1: 'publiés avec succès.',
    muestras_publicadas_txt2: 'vous pouvez les vérifier dans ',
    sin_ingresar_muestra: 'Pas d’échantillon de',
    sin_ingresar_muestra_txt1: 'à publier',
    el_param: 'Paramètre',
    el_param_txt1: 'de l’échantillon',
    el_param_txt2: 'il n’a pas été publié',
    la_muestra: 'L’échantillon',
    la_muestra_txt1: 'n’a pas été publié pour avoir',
    la_muestra_txt2: 'Veuillez relancer le PCR.',
    pocillo: 'Puits',
    muestra: 'ID de l’échantillon',
    descripcion: 'Description de l’échantillon ',
    sample_status: 'État d’échantillon',
    origen: 'Origine',
    resultado: 'Résultat',
    otro: 'Autre',
    generar_pdf: 'Générer un PDF',
    publicar: 'Publier',
    salir: 'Quitter',
    m_resultados: 'Échantillon avec résultats',
    m_resultados_txt1: 'L’échantillon a déjà des résultats et est publié.',
    m_resultado_txt2: 'Souhaitez-vous poster à nouveau ?',
    aceptar: 'Accepter',
    cancelar: 'Annuler',
    // modal analisis
    _ot: 'Ordre de travail',
    la_ot_txt1: 'contient',
    la_ot_muestra_txt2: 'échantillons',
    la_ot_muestra_txt3: 'échantillon',
    seleccionar_kit_pcr: 'Sélectionner le kit PCR',
    kit_pcr: 'Kit PCR',
    Selectlaboratorytest: 'Sélectionner le test de laboratoire',
    c_negativo: 'Contrôle négatif',
    c_positivo: 'Contrôle positif',
    limpiar: 'Effacer',
    txt1: 'N\'oubliez pas de vérifier que chaque puits attribué à votre échantillon est associé au test de laboratoire correspondant, et n\'oubliez pas d\'inclure vos contrôles.',
    guardar: 'Enregistrer',
    // kit ensayos
    /* eslint-disable */







    ensayo: 'test de laboratoire',




  },
  ensayos: {
    no_sub_options: 'Pas de sous-options',
    no_results: 'Aucun résultat trouvé…',
    configCountResults: 'Configurer les résultats de type de comptage',
    definirEnsayos: 'Définir les plages de tests de laboratoire',
    rangosLeyenda: 'Définir les plages qu’aura le test de laboratoire',
    rango1: 'Plage 1',
    rango2: 'Plage 2',
    Rowsperpage: 'Lignes par page :',
    viewunitsofmeasure: 'Afficher les unités de mesure',
    idioma: 'Langue',
    Addconditional: 'Ajouter un conditionnement',
    Assignunitsofmeasure: 'Affecter les unités de mesure',
    Testname: 'Nom de test',
    Testcategory: 'Catégorie de test',
    Status: 'État',
    Setuptestparameters: 'Configurer les paramètres de test',
    Setupandaddrelevant: 'Configurer et ajouter des informations pertinentes sur les paramètres de détection de ce test',
    Basicinformation: 'Informations de base',
    Pleasefill: 'Veuillez saisir les informations demandées pour créer votre test',
    Classesoutofspec: 'Classes hors spécifications',
    Selectcategory: 'Sélectionner une catégorie',
    Addcondition: 'Ajouter une condition',
    Informationrequired: 'Informations obligatoires',
    warning: 'Avertissement',
    ConfigurarEnsayo: 'Configuration des tests de laboratoire',
    Matrices: 'Matrice',
    clasesnocumplen: 'Classes qui ne satisfont pas',
    Clases: 'Classes',
    Addthresholds: 'Ajouter des seuils',
    cusromparam: 'Modifier les paramètres',
    labs: 'Laboratoires',
    costo: 'Coût',
    cod_ensayo: 'Code de test',
    tiempo_result: 'Temps pour l’obtention des résultats (heure)',
    activite: 'Activer les tests TAAG',
    active_lab: 'Activer les tests de laboratoire TAAG',
    txt_kit: 'Ajouter des tests de laboratoire compatibles avec les kits real-time PCR de TAAG Genetics.',
    add: 'Ajouter',
    Addparameter: 'Ajouter un paramètre',
    bajo: 'Faible',
    alto: 'Élevé',
    alto_riesgo: 'Risque Élevé',
    bajo_riesgo: 'Faible Risque',
    pathogeno: 'Agent pathogène',
    indicador: 'Indicateur',
    deteriorante: 'Détériorant',
    cerrar: 'Fermer',

    // modal- param condition
    txt_param_condition: 'Conditions de paramètres',
    t_param: 'Paramètre',
    classifitacion: 'Classification',
    RiskLevel: 'Niveau de risque',
    you_param: 'Vos paramètres',
    add_param: 'Nom de paramètre',

    // modal-tipo ensayo

    txt_title: 'Configurer les paramètres',
    test: 'Test',
    tezt_category: 'Catégorie de test',
    tipo_test: 'Saisir le nom de test',
    category: 'Sélectionner la catégorie de test',

    // modal-configuracion recuento

    title: 'Paramètres des résultats de type comptage',
    title2: 'Veuillez sélectionner la notation numérique dans laquelle vos résultats de type comptage seront signalés',

    // txt- tooltpis
    txt_namerepot: 'Ce nom apparaîtra dans le rapport.',
    txt_t_ensayo: 'Ici, vous sélectionnez ou créez un type de test, c’est-à-dire biologie moléculaire, tests classiques, etc.',
    txt_desc: 'Cette description apparaîtra dans le rapport.',
    txt_metho: 'Cette méthodologie apparaîtra dans le rapport.',
    txt_param: 'C’est ce que votre test détectera, par exemple, Staphylococcus aureus, taux de cuivre, total de coliformes, etc. Vous pouvez ajouter autant de paramètres que vous le souhaitez en fonction des limites de votre test.',
    txt_t_tecnica: 'Si vous avez sélectionné Microbiologie traditionnelle, Test moléculaire ou Test rapide, nous vous invitons à ajouter des informations complémentaires sur vos paramètres, tels que le niveau de risque ou la classification, afin de nous permettre d’affiner nos algorithmes pour une analyse optimale.',
    txt_result: 'C’est ainsi que vous communiquerez vos résultats.',

    ensayos_lab: 'Tests en laboratoire',
    crear_edita: 'Créer, modifier et gérer des tests en laboratoire',
    buscar: 'Rechercher',
    crear_nuevo_ensayo: 'Ajouter un nouveau test de laboratoire',
    mantebedor: 'Mainteneur de matrice',
    resumen: 'Résumé de test',
    ningun_registro: 'Aucun enregistrement trouvé',
    r_pagina: 'Enregistrer par page',
    inactivo: 'Inactif',
    activo: 'Actif',
    detalle: 'Afficher les détails',
    mantenedor: 'Mainteneur de paramètres',
    crear_param: 'Créer des paramètres',
    guardar: 'Enregistrer',
    guardar_ensayo: 'Enregistrer le test',
    param_manager: 'Gestionnaire de paramètres',
    nombre: 'Nom de test',
    tipo: 'Type',
    param_mtz: 'Paramètres et matrices',
    option: 'Option',
    creado: 'Date de création',
    clasif: 'Classification',
    rules_txt0: 'Nom de test requis',
    rules_txt1: 'Type de test requis',
    rules_txt2: 'Type de méthodologie requis',
    rules_txt3: 'Analyse de site requise',

    rules_txt4: 'Produit requis',
    rules_txt5: 'Vous devez sélectionner au moins un paramètre. S’il n’y a pas de paramètres disponibles dans la liste déroulante, veuillez les ajouter dans le gestionnaire de paramètres.',
    rules_txt6: 'Type de résultat requis',
    rules_txt7: 'Type de kit requis',
    rules_txt8: 'Description du test de laboratoire requise',
    rules_txt9: 'Méthodologie requise',
    rules_txt10: 'Vous devez sélectionner une entreprise',
    rules_txt11: 'Temps de résultat requis ',
    rules_txt12: 'Type technique requis',
    rules_txt13: 'Nom de l’essai requis',

    rules_txt14: 'Seuil requis',
    rules_txt15: 'Type d’échantillon requis',
    rules_txt_condicionparam: 'Paramètres et conditions sélectionnés',


    // modal
    param_matrices: 'Paramètres et matrices',
    matrices: 'Matrice',
    param: 'Paramètre',
    editar: 'Modifier',
    cancelar: 'Annuler',


    // cierre modal

    agregar_ensayo: 'Ajouter un nouveau test de laboratoire',
    nombre_ensayo: 'Nom de test',
    estado: 'État de test',
    tipo_ensayo: 'Type de test',
    Name_tipo_ensayo: 'Nom du type de test',
    info_tecnicas: 'Informations techniques sur votre test de laboratoire',

    clasif_ensayo: 'Classification de l’essai',
    desc: 'Description (max. 200 caractères)',
    testnamea: 'Nom de test',
    activado: 'Actif',

    metodologia: 'Méthodologie',
    txt1: 'Non trouvé',
    txt2: 'Appuyez sur Entrée pour créer.',
    tipo_tecnica: 'Spécification des paramètres',
    tipo_resultado: 'Type de résultat',
    asignacion: 'Affecter des matrices',
    select: 'Sélectionner le type d’échantillons/matrices qui seront analysés avec ce test',
    unidades: 'Saisir l’unité de mesure',
    thparam: 'Paramètre',
    thumbral: 'Seuil',
    thunidad: 'Unités',

    definir: 'Définir les seuils de détection et les unités de mesure',
    selectumbrales: 'Sélectionner le type d’échantillons/matrices qui seront analysés avec ce test',
    umbral: 'Saisir la valeur de seuil',
    unidad_medida: 'Unités de mesure',
    alimento: 'Aliments',
    superficies: 'Surfaces',
    manipuladores: 'Manipulateurs',
    aguas: 'Eau',
    ambientes: 'Environnements',
    txt3: 'Les seuils ne s’appliquent pas pour le type de résultat « Absence/Présence »',

    PA: 'Présence/Absence',
    RTO: 'Compte',
    Ext: 'Externalisé',


    rules_txt_condicion: 'Niveau et classification sélectionnés',
    update_succes: 'Mise à jour réussie',
    save_succes: 'Enregistrement réussi',
    selectedmatriz: 'Sélectionner une matrice',
    delete_param: 'Paramètre supprimé',
    save_param: 'Paramètre enregistré',
    // modal eliminar ensayo
    delete_title: 'Avertissement',
    delete_cuestion: 'Souhaitez-vous vraiment supprimer ce test \n',
    delete_cuestion2: 'Cette opération ne pourra pas être annulée.',
    activekits: 'Kits actifs Taag',
    listakits: 'Liste des kits',

    umbralesalimento: 'Seuils non définis dans les aliments',
    umbralessuperficie: 'Seuils non définis dans les surfaces',
    umbralesmanipuladores: 'Seuils non définis dans les manipulateurs',
    umbralesagua: 'Seuils non définis dans l’eau',
    umbralesambiente: 'Seuils non définis dans les environnements',
    


  },
  matrices: {
    Alimento: 'Aliments',
    Alimentos: 'Aliments',
    Aguas: 'Eau',
    Agua: 'Eau',
    Superficies: 'Surface',
    Manipuladores: 'Manipulateurs',
    Ambientes: 'Environnement',
  },
  modal_tipo_ensayo: {
    TradicionalMicrobiology: 'Microbiologie classique',
    MolecularTesting: 'Tests moléculaires',
    RapidTesting: 'Test rapide',
    ChemicalTesting: 'Tests chimiques',
    Other: 'Autre',
  },
  tipoResul: {
    PresenceAbsence: 'Présence/Absence',
    Count: 'Compte',
    Exeternalized: 'Saisie ouverte',
    
  },
  submatriz: {
    MateriaPrima: 'Matière première',
    ProductoTerminado: 'Produit fini',
    SemiElaborado: 'Semi-élaboré',
    zona1: 'zone 1',
    zona2: 'zone 2',
    zona3: 'zone 3',
    critica: 'Critique',
    nocritica: 'Non critique',
    presanitizacion: 'Prédésinfection',
    postsanitizacion: 'Post-désinfection',
    enuso: 'En cours d’utilisation',
    guante: 'Mains gantées',
  },
  solicitudes: {
    fechaasignado: 'Date attribuée',
    Horainicio: 'Heure de début',
    Horatermino: 'Heure de fin',
    Success: 'Succès',
    Eventoeliminadocorrectamente: 'Événement supprimé avec succès',
    Advertencia: 'Avertissement',
    Deseaeliminarestasolicitud: 'Souhaitez-vous supprimer cet événement ?',
    aceptar: 'Accepter',
    Max200characters: '(max. 200 caractères)',
    fecha: 'Date',
    horainicio: 'Heure de début',
    horatermino: 'Heure de fin',
    Asunto: 'Sujet',
    Descripcion: 'Description',
    Eliminar: 'Supprimer',
    Editar: 'Modifier',
    Ingresarcomentarionomayora200caracteres: 'Saisir un commentaire de max. 200 caractères',
    success: 'Succès',
    success1: 'Les modifications ont été effectuées correctement',
    mostrar: 'Afficher les demandes',
    ocultar: 'Masquer les demandes',
    ingresada: 'Demandes saisies',
    cliente: 'Client',
    planta: 'Installation',
    muestreador: 'Échantillonneur',
    fecha_creacion: 'Date de création',
    fecha_programada: 'Date prévue',
    estado: 'État',
    detalle: 'Détails',
    encargado: 'Gestionnaire local',
    fecha_presunta: 'Date présumée',
    dir: 'Adresse',
    materia_prima: 'Matière première',
    f_elab: 'Date d’élaboration',
    f_ven: 'Date d’expiration',
    parametros: 'Paramètres',
    opciones: 'Options',
    // modalensayo
    title_ensayo: 'TEST DE LABORATOIRE À RÉALISER',
    muestra: 'Échantillon',
    n_ensayo: 'Nom de test',
    desc: 'Description',
    lote: 'Lot',
    revisar: 'Examiner',

    // modaledit
    title_edit: 'Éditeur d’échantillons',

    // coordinacion
    coo: 'Liste de demandes',
    ordenar: 'Trier par',
    total_soli: 'TOTAL DES DEMANDES',
    hoy: 'Aujourd’hui',
    dia: 'Jour',
    semana: 'Semaine',
    mes: 'Mois',
    dias4: '4 jours',

    // MODAL COORDINACION
    EditarEvento: 'Modifier un événement',
    crear_evento: 'Créer un événement',
    asunto: 'Entreprise',
    resumen_solicitado: 'Résumé demandé',
    direccion: 'Adresse',
    resumen_muestra: 'Résumé de l’échantillon',
    cancelar: 'Annuler',
    obvs: 'Observations',
    codigo_barras: 'code-barres',
    DetallesolicitudID: 'Détail de l’ID de demande',
    Detallesolicitud: 'Détails de demande',
    deletesoli: 'Supprimer la demande',
    Editarsolicitud: 'Modifier la demande',
    servicio: 'Services',
    guardar: 'Enregistrer',
    Desearealizarloscambios: 'Vous souhaitez apporter les modifications',
    region: 'région',
    manipuladores: 'Manipulateurs',
    agua: 'Eau',
    ambientes: 'Environnements',
    superficies: 'Surfaces',
    alimentos: 'Aliments',
  },
  uploadfile: {
    ControldeVersiones: 'Contrôle Version',
    Clientes: 'Clients',
    Todos: 'Tous les clients',
    Ensayo: 'Analyse de laboratoire',
    versionactualizada: 'Version mise à jour',
    Zipfile: 'Fichier Zip',
    Version: 'Version',
    NombredelCarpetaprincipal: 'Nom du dossier parent',
    Nombredelarchivoprincipal: 'Nom du fichier principal',
    Clear: 'Effacer',
    Submit: 'Soumettre',
    Historial: 'Historique',
    showall: 'Afficher tout',
    Matrices: 'Matrice',
    Archivo: 'fichier',
    Ruta: 'chemin',
    RutaBackup: 'Chemin de sauvegarde',
    Activo: 'Actif',
    Creado: 'Créé',
    Cliente: 'Client',
    Ensayos: 'Tests de laboratoire',
  },

  
};

export default fr;
