import Vue from 'vue';

import VueI18n from 'vue-i18n';
import es from '../lang/es';
import en from '../lang/en';
import fr from '../lang/fr';
import nl from '../lang/nl';

Vue.use(VueI18n);

const messages = {
  es,
  en,
  fr,
  nl,
};
export const il8n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'es',
  messages,
});

export default il8n;
