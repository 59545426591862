<!-- eslint-disable -->
<style lang="scss">
/* eslint no-tabs: ["error", { allowIndentationTabs: false }] */
.loginscreen.middle-box{
	padding-top: 80px!important;
}
#header{
	--bgHeader: transparent;
	--colorBanner: #fff;
	--iconColor: #fff;
	--colorAnchor: #111;
	color: var(--colorBanner, #111);
	transition: background-color 0.5s ease;
	position: sticky;
	position: fixed;
	width: 100%;
	height: 80px;
	text-align: center;
	top: 0;
	z-index: 99;
	background-color: var(--bgHeader, #fff);
	display: flex;
	align-items: center;
}
@media screen and (min-width: 992px){
	#header{
		padding: 1rem 2rem;
	}
}
@media screen and (max-width: 768px){
	.content-image {
		padding: 0;
	}
}

#header.bgTopBanner{
	--bgHeader: #000;
}
#header svg{
	--colorBanner: #fff;
}
#header.bgTopBanner svg{
	--colorBanner: #fff;
}
#header #logo {
	float: left;
	border: 0;
	padding: 0;
	padding: 5px 0;
	font-size: 1em;
	font-size: 0.75em;
	z-index: 9;
	position: relative;
}
#ico_collapse {
	cursor: pointer;
}
#ico_collapse {
	display: none;
	width: 30px;
	height: 30px;
	float: right;
	position: relative;
	z-index: 11;
}
.fRight {
	float: right;
}
.banner {
	height: 100vh;
	min-height: 600px;
	color: #fff;
}
#navContent .link {
	font-weight: 500;
	font-size: 15px;
	margin-left: 5px;
	padding: 5px 0;
	color: var(--colorBanner, #111);
}
#navContent .linkLight{
	font-weight: bold;
}
#navContent .link:hover {
	color: #3d48f3;
}
#navContent .link:hover svg {
	fill: #3d48f3;
}
.btn-group [target="_blank"] svg {
	transition: none;
	transform: initial!important;
}
.no-anchor{
	pointer-events: none;
}
.rotate--90deg{
	transform: rotate(-90deg)!important;
}
.rotate--180deg{
	transform: rotate(-180deg)!important;
}
.btn-group svg.rotate--90{
	transform: rotate(-90deg)!important;
}
.o-header__col{
	width: 100%;
}
.selectidioma{
	background: inherit;
}
.selectidioma,
.selectidioma option{
	background-color: var(--bgHeader, #fff);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.selectidioma{
	background-image: url(/assets/img/arrowdownwhite.svg);
	background-repeat: no-repeat;
	background-position: right center;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
	background-size: 13%;
}
.selectidioma:focus{
    border: 1px solid #000000 !important;
}
.btn-primary{
	opacity:1!important;
}
.o-login__content{
	margin: auto;
}
.c-form-group{
	margin: 2rem auto!important;
}
.c-form-group__input{
	border: none;
}
.o-login__content{
	margin: 3rem auto;
}
.o-login__header .welcome{
	font-weight: 700;
	color: #ADADAD;
	text-transform: initial;
	font-size: 25px;
}
.o-login__header .h3{
	font-size: 25px;
	font-weight: 700;
	color: #000;
}
.txt1{
	font-size: 16px;
	font-weight: 400;
	color: #ADADAD;
}
.c-btn-login.bg-black{
	font-size: 16px;
	font-weight: 500;
	border: none;
}
.btn-primary:active:hover,
.btn-primary.active:hover,
.btn-primary.active:focus{
	background-color: #111;
}
.pace {
	display: none!important;
}
.pace .pace-progress{
	background: #111;
}
.d-flex{
	display: flex !important;
}
.display-flex-align-items-center{
	display: flex;
align-items: center;
}
.fs16{
	font-size: 16px !important;
}
.mr3x{
	margin-right: 4px;
}
.pl27px{
	padding-left: 26px !important;
}
.opensans{
	font-family: "Open Sans" !important;
}
.bg-option{
	background-color: #ffffff !important;
	color: #000000;
	font-size: 14px;
	font-weight: 400;
}
</style>
<template>

<div id="header" class="o-header bgTopBanner topBanner">
	<div style="width: 100%">
		<div class="display-flex-align-items-center row o-header__row">
			<div class=" d-flex normal col-10">
				<p class="opensans mb-0 bold mr-1 fs16 mr3x">TAAG</p>
				<p class="opensans mb-0 normal fs16">Genetics</p>
			</div>
			<div class="col-md-2 o-header__col">
				<div id="navContent" class="fRight animate1 d-none d-md-block">
					<div class="btn-group">
						<div class="position-select">
							<select name="selectIdioma" class="selectidioma" onchange="change_lang(this)">
								<option class="bg-option" value="0">Español</option>
								<option class="bg-option" value="1">English</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<script>
export default {
  name: 'Header',
  data: () => ({
    loading: false,
  }),
  methods: {
  },
};
</script>
